import React, { useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Header from '../../../components/header';
import ReactPhoneInput from 'react-phone-input-2';
import { signupImg } from '../../../components/Images';
import { forgotPasswordAction } from "../../../actions/forgotPasswordAction";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../helper/swal";
import { encryptRequest } from "../../../helper/crypto";


const ForgotPassword = () => {
  const { forgotPasswordData } = useSelector((state) => state.forgotPassword);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [isChecked, setIsChecked] = useState("phone");
  const token = localStorage.getItem("adminLoginToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Forgot Password Form Initial Values
  const initialValues = {
    email: "",
    contactNumber: "",
    countryCode: "",
  }

  const forgotPasswordSchema = Yup.object().shape({
    email: isChecked === "email" ? Yup.string().email("Please enter a valid email id").required("Email is required.") : Yup.string(),
    contactNumber: isChecked === "phone" ? Yup.string().min(5, "Please enter a valid phone number").required("Phone Number is required.") : Yup.string()
  });


  /**
   * 
   * @param {*} values 
   */
  // Forgot password form Submit
  const submitHandler = async (values) => {
    if (isChecked === "phone") {
      setLoading(true);
      const data = { contactNumber: encryptRequest(values?.contactNumber), countryCode: parseInt(countryCode) }
      dispatch(forgotPasswordAction(data));
    } else {
      setLoading(true);
      const data = { email: encryptRequest(values?.email) }
      dispatch(forgotPasswordAction(data));
    }
  };


  useEffect(() => {
    if (token) {
      window.history.back();
    }
    const { httpStatus, message, success } = forgotPasswordData;

    if (httpStatus === 200 && success === true) {
      setLoading(false);

      showToast(message, "success", false, 4500);
      navigate("/login");
      dispatch({ type: "RESET_STATE" });
    } else if (success === false) {
      setLoading(false);

      showToast(message, "error", true);
      dispatch({ type: "RESET_STATE" });
    }
  }, [forgotPasswordData, navigate, dispatch, token]);

  return (
    <>
      <div className="login-wrapper login-page" >
        <Header />
        <Container fluid>
          <Row>
            <Col lg={6} xs={12} className="px-0 banner-image-box d-none d-md-block">
              <div className="banner-image">
                {/* <img className='login-image' src={loginImg} alt="logo" /> */}
                <img className='signup-image' src={signupImg} alt="logo" />
              </div>
            </Col>
            <Col lg={6} xs={12} className="px-0">
              <div className='form-wrapper'>
                <div className='tabs-wrapper'>
                  <div className="auth-card">
                    <h1 className="title-3 pb-2">Forgot Password</h1>
                    <div className="d-flex">
                      <label className="custom-radio-btn me-3">
                        <input type="radio" name="login" checked={isChecked === "phone"} onChange={() => setIsChecked("phone")} />
                        <span className="check"></span>
                        <span className="form-label">By Phone Number</span>
                      </label>
                      <label className="custom-radio-btn">
                        <input type="radio" name="login" checked={isChecked === "email"} onChange={() => setIsChecked("email")} />
                        <span className="check"></span>
                        <span className="form-label">By Email</span>
                      </label>
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={forgotPasswordSchema}
                      onSubmit={(values) => {
                        // same shape as initial values
                        submitHandler(values);
                      }}
                    >
                      {({ errors, touched, handleBlur, setFieldValue, values, handleSubmit }) => (
                        <Form>
                          {isChecked === "phone" ?
                            (
                              <div className="mb-4 pb-3">
                                <label htmlFor="contactNumber" className="form-label">
                                  Phone number
                                </label>
                                <div className="custom-number-input">
                                  <ReactPhoneInput
                                    name="contactNumber"
                                    id="contactNumber"
                                    inputProps={{
                                      name: 'contactNumber',
                                    }}
                                    onBlur={handleBlur}
                                    country={"us"}
                                    onChange={(value, country) => { setFieldValue("contactNumber", value.slice(country.dialCode.length)); setCountryCode(country.dialCode) }}
                                  />
                                </div>
                                {touched?.contactNumber && errors?.contactNumber && (
                                  <span className="error">{errors?.contactNumber}</span>
                                )}
                              </div>

                            ) : (
                              <div className="mb-4 pb-3">
                                <label htmlFor="email" className="form-label">
                                  Email address
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Enter your Email"
                                ></Field>
                                <ErrorMessage component="div" className="error" name="email" />
                              </div>
                            )}
                          <div className="text-center mb-3">
                            <Button
                              type="submit"
                              className="btn btn-primary btn-large round"
                            >
                              {loading === true ? (
                                <>
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>

                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <div>Forgot Password</div>
                              )}
                            </Button>
                          </div>
                          <div className="remember-me mb-4 justify-content-center">
                            <label className="form-check-label me-2" htmlFor="account">
                              Have an account?
                            </label>
                            <Link className="signin" to="/login">
                              Sign in
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
