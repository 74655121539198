import axios from "axios";

// for normal environment
const baseURL = process.env.REACT_APP_ENV_VARIABLE_KEY;

const now = new Date();

// Get the timezone offset in minutes
const timezoneOffset = now.getTimezoneOffset();

// Convert the offset to hours and minutes
const hours = Math.floor(Math.abs(timezoneOffset) / 60);
const minutes = Math.abs(timezoneOffset) % 60;

// Determine the sign of the offset
const sign = timezoneOffset < 0 ? "+" : "-";

// Format the offset string
const offsetString = `${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    timezone_offset: -330,
    language: "en-US",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem('adminLoginToken'),
    offset: offsetString,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('adminLoginToken');

    // if (token) {
    config.headers["Authorization"] = token;
    // }

    return config;
  },
);
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      setTimeout(
        () => window.location.href = '/login',
        1500
      );

    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
