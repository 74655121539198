import axios from "axios";

export const forgotPasswordAction = (data) => {
  const effectiveUrl = process.env.REACT_APP_ENV_VARIABLE_KEY;
  return async (dispatch) => {
    axios({
      url: `${effectiveUrl}/api/Users/forgotPassword`,
      method: "put",
      data: data,
    })
      .then((response) =>
        dispatch({ type: "FORGOT_PWD", payload: response.data })
      )
      .catch((error) =>
        dispatch({ type: "FORGOT_PWD", payload: error.response.data }),
      );
  };
};
