export const logoImg = "/images/logo.png";
export const darklogo = "/images/dark_logo_header.svg";
export const logoBlue = "/images/logo_blue.svg";
export const loginImg = "/images/login-img.png";
export const signupImg = "/images/join-artemizz.png";
export const resetpasswordPic = "/images/reset-password.png";
export const appleImg = "/images/apple.png";
export const QrCode = "/images/qr-code.png";
export const profileImg = "/images/profile.jpg";
export const arrowimg = "/images/click.svg";
export const thumbnailImg = "/images/thumbnail.png";
export const artemizz_mini_logo = "/images/artemizz-mini-logo.png";
export const problem_need_solution = "/images/problem-need-solution.png";
export const personal = "/images/personal.svg";
export const guardian = "/images/guardian.svg";
export const plus = "/images/plus.svg";
export const minus = "/images/minus.svg";
export const app_Store = "/images/App_Store.svg";
export const insta = "/images/insta.png";
export const fb = "/images/fb.svg";
export const twitter = "/images/twitter.svg";
export const partenership = "/images/partenership.svg";
export const support = "/images/support.svg";
export const artemizz_logo = "/images/artemizz-logo.svg";
export const banner_img = "/images/banner-img.png";
// timeline
export const friendshare = "/images/Landing-page-timeline/friendshare.svg";
export const timeline_top = "/images/Landing-page-timeline/timeline_top.svg";
export const device_timeline =
  "/images/Landing-page-timeline/device_timeline.png";
export const device_recorder =
  "/images/Landing-page-timeline/device_recorder.png";
export const device_friendshare =
  "/images/Landing-page-timeline/device_friendshare.png";
export const device_map = "/images/Landing-page-timeline/device_map.png";
export const device_sos = "/images/Landing-page-timeline/device_sos.png";
export const map = "/images/Landing-page-timeline/map.svg";
export const recorder = "/images/Landing-page-timeline/recorder.svg";
export const timeline_active =
  "/images/Landing-page-timeline/timeline_active.svg";
export const sos = "/images/Landing-page-timeline/sos.svg";
export const footer_img = "/images/footer-img.png";
// timeline slider img
export const dateicon = "/images/Landing-page-timeline/date.svg";
export const dataicon = "/images/Landing-page-timeline/data.svg";
export const rideicon = "/images/Landing-page-timeline/ride.svg";
export const safety_checkin =
  "/images/Landing-page-timeline/safety_checkin.svg";
export const tamper_proof = "/images/Landing-page-timeline/tamper_proof.svg";

export const footprint = "/images/Landing-page-timeline/footprint.svg";
export const location = "/images/Landing-page-timeline/location.svg";
export const posts = "/images/Landing-page-timeline/posts.svg";

export const chat = "/images/Landing-page-timeline/chat.svg";
export const connect = "/images/Landing-page-timeline/connect.svg";
export const inform = "/images/Landing-page-timeline/inform.svg";

export const phone_call = "/images/Landing-page-timeline/phone_call.svg";
export const sos_red = "/images/Landing-page-timeline/SOS_red.svg";
export const list = "/images/Landing-page-timeline/list.svg";
export const mask = "/images/Landing-page-timeline/mask.svg";
export const schedule = "/images/Landing-page-timeline/schedule.svg";
export const map_dark = "/images/Landing-page-timeline/map_dark.svg";

export const card1 = "/images/landing-personal-account/card1.svg";
export const card2 = "/images/landing-personal-account/card2.svg";
export const card3 = "/images/landing-personal-account/card3.svg";
export const card4 = "/images/landing-personal-account/card4.svg";
export const card5 = "/images/landing-personal-account/card5.svg";
export const card6 = "/images/landing-personal-account/card6.svg";
export const pin1 = "/images/landing-personal-account/pin1.svg";
export const pin2 = "/images/landing-personal-account/pin2.svg";
export const pin3 = "/images/landing-personal-account/pin3.svg";
export const pin4 = "/images/landing-personal-account/pin4.svg";
export const pin5 = "/images/landing-personal-account/pin5.svg";
export const pin6 = "/images/landing-personal-account/pin6.svg";
export const road = "/images/landing-personal-account/road.svg";

export const profileEdit = "/images/profile-edit.svg";

// guardian account
export const step_1 = "/images/guardian-account/step_1.png";
export const step_2 = "/images/guardian-account/step_2.png";
export const step_3 = "/images/guardian-account/step_3.png";
// why artemizz better
export const center_logo = "/images/center_logo.svg";
export const privacy = "/images/privacy.svg";
export const sharing = "/images/sharing.svg";
