import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Accordion } from "react-bootstrap";
import { faqList } from "../../Services/api/endpoints";
import noToken from "../../Services/middleware/noToken";
import Loader from "../loader/loader";
import { showToast } from "../../helper/swal";
const FaqPage = () => {
  const [faqListData, setFaqListData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function faqData() {
    try {
      setLoading(true);
      const response = await noToken(`${faqList}`);
      const apiData = response.data.data;
      setFaqListData(apiData);
      if (
        response.data?.httpStatus === 200 &&
        response.data?.success === true
      ) {
        setLoading(false);
      } else if (response.data?.success === false) {
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        showToast(error.response.data?.message, "error", true);
      }
    }
  }
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    faqData();
  }, [])

  return (
    <>

      {loading === true ? (
        <>
          <Loader />
        </>
      ) : (
        <Container>
          <div className="faq-content">
            <h2
              className="heading"
            // data-aos="fade-right"
            // data-aos-offset="10"
            >
              FAQ
            </h2>
            <Accordion>
              {
                faqListData?.map((item, i) => (
                  <Accordion.Item
                    key={i}
                    eventKey={i}
                  // data-aos="fade-left"
                  // data-aos-offset="10"
                  >
                    <Accordion.Button as="div" className="accordion-header">
                      <h4>{item?.question}</h4>
                      <span>
                        <i className="icon-expand"></i>
                        <i className="icon-minus"></i>
                      </span>
                    </Accordion.Button>
                    <Accordion.Body>
                      <p className="mb-0">
                        {item?.answer}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </div>
        </Container>
      )}

    </>
  )
}


export default FaqPage;