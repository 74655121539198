import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import EditProfile from "../../components/modals/edit-profile";
import ChangePassword from "../../components/change-password";
import moment from "moment";
import { getUserProfileDetail } from "../../Services/api/endpoints";
import axiosInstance from "../../Services/middleware/index";
import Loader from "../../components/loader/loader";
import { formatPhoneNumber } from "../../helper/phoneNumberFormat";
import { showToast } from "../../helper/swal";
import { useDispatch } from "react-redux";
import { decryptRequest } from "../../helper/crypto";

const MyProfile = ({ handleClick }) => {
  const [loading, setLoading] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const dispatch = useDispatch();

  const [profileData, setProfileData] = useState({
    id: "",
    userId: "",
    fName: "",
    lName: "",
    email: "",
    gender: "",
    phone: "",
    cityName: "",
    state: "",
    isEmailVerified: "",
    dob: "",
    sosDefaultTime: "",
    profilePic: "",
    url: "",
  });

  const fetchProfileData = () => {
    userProfileData();
  };

  async function userProfileData() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${getUserProfileDetail}`);

      const apiData = response.data.data;

      setProfileData({
        ...profileData,
        id: apiData?.id,
        userId: apiData?.userId,
        fName: decryptRequest(apiData?.fName),
        lName: decryptRequest(apiData?.lName),
        email: decryptRequest(apiData?.email),
        gender: apiData?.gender,
        phone: decryptRequest(apiData?.phone),
        cityName: apiData?.cityName,
        state: apiData?.state,
        isEmailVerified: apiData?.isEmailVerified,
        dob: apiData?.dob,
        sosDefaultTime: apiData?.sosDefaultTime,
        profilePic: apiData?.profilePic,
        url: apiData?.url,
      });
      if (
        response.data?.httpStatus === 200 &&
        response.data?.success === true
      ) {
        setLoading(false);
        dispatch({ type: 'UPDATEUSER', payload: apiData });
      } else if (response.data?.success === false) {
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);

        showToast(error.response.data?.message, "error", true);
      }
    }
  }

  useEffect(() => {
    userProfileData();
  }, []);

  return (
    <>
      <div className="profile-wrapper">
        <Row>
          <Col md={6}>
            <div className="profile-title">
              <h3 className="title-small text-primary mb-0">
                Personal Information
              </h3>
              <button
                type="button"
                className="btn-transparent edit-profile p-0"
                onClick={() => {
                  setOpenEditProfileModal(true);
                  handleClick();
                }}
              >
                <i className="icon-user-edit"></i>
              </button>
            </div>
            <div className="profile-body">
              {loading === true ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <div className="profile-top-details text-center mb-4">
                    <div className="profile-img">
                      {profileData?.profilePic ? (
                        <img src={profileData?.url} alt={profileData?.fName} />
                      ) : (
                        <div className="avatar-name">
                          {profileData?.fName.slice(0, 1)}
                        </div>
                      )}
                    </div>
                    <h3>
                      {profileData?.fName}{" "}
                      {profileData?.lName && (profileData?.lName)}
                    </h3>
                  </div>
                  <div className="profile-details">
                    <div className="row-icon">
                      <i className="icon-user"></i>
                      <div className="right-detail">
                        <span>Name</span>
                        <p>
                          {profileData?.fName}{" "}
                          {profileData?.lName && (profileData?.lName)}
                        </p>
                      </div>
                    </div>
                    <div className="row-icon">
                      <i className="icon-mail small-font"></i>
                      <div className="right-detail">
                        <span>Email Address</span>
                        <p>
                          {profileData?.email}
                          {profileData?.isEmailVerified}
                        </p>
                      </div>
                      {profileData?.isEmailVerified === true ? (
                        <i className="checker icon-check-circle"></i>
                      ) : (
                        <i className="checker icon-info"></i>
                      )}
                    </div>
                    <div className="row-icon">
                      <i className="icon-phone"></i>
                      <div className="right-detail">
                        <span>Phone Number</span>
                        <p>
                          {formatPhoneNumber(profileData?.phone)}
                        </p>
                      </div>
                      <i className="checker icon-check-circle"></i>
                    </div>
                    <div className="row-icon">
                      <i className="icon-calender"></i>
                      <div className="right-detail">
                        <span>Date of Birth</span>
                        <p>
                          {moment(profileData?.dob).format("MMMM D, yyyy")}
                        </p>
                      </div>
                      <i className="checker icon-check-circle"></i>
                    </div>
                    <div className="row-icon">
                      <i className="icon-user"></i>
                      <div className="right-detail">
                        <span>Gender</span>
                        <p>{profileData?.gender ? profileData?.gender : "NA"}</p>
                      </div>
                    </div>
                    <div className="row-icon">
                      <i className="icon-building"></i>
                      <div className="right-detail">
                        <span>City, State</span>
                        <p>
                          {" "}
                          {profileData?.cityName}
                          {profileData?.state ? "," : ""} {profileData?.state}
                          {(profileData?.cityName || profileData?.state) ? null : "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col md={6}>
            <ChangePassword handleClick={handleClick} />
          </Col>
        </Row>
      </div>

      {openEditProfileModal && (
        <EditProfile
          handleClick={handleClick}
          openEditProfileeModal={openEditProfileModal}
          closeEditProfileModal={() => setOpenEditProfileModal(false)}
          fetchProfileData={() => fetchProfileData()}
        />
      )}
    </>
  );
};
export default MyProfile;
