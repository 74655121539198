import React from "react";
import Header from "../header";
import LeftSideBar from "../sidebar";

const Layout = (props) => {

  function sidebarToggle() {
    document.body.classList.toggle("toggle-sidebar");
  }

  return (
    <div className='main-wrapper'>
      <Header />
      <div className='content-wrapper'>
        <LeftSideBar />
        <div className="main-content">
          <div className="dashbord-wrapper">
            {props.children}
          </div>
          <div className='sidebar-overlay' onClick={sidebarToggle}></div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
