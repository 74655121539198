import axios from "axios";

export const loginAdminPanel = (data) => {
  // console.log("id  in action", id);
  // console.log(" pass in action", pass);

  // ;
  // const headers = { timezone_offset: -330 };
  const effectiveUrl = process.env.REACT_APP_ENV_VARIABLE_KEY;
  return async (dispatch) => {
    axios({
      url: `${effectiveUrl}/api/Users/Login`,
      method: "post",
      data: data,
    })
      .then(
        async (response) => await dispatch(setAdminCredentials(response.data))

      )
      .catch(
        async (error) =>
          await dispatch(setAdminCredentials(error.response.data))

      );
  };
};

export const setAdminCredentials = (loginResponse = null) => {
  if (loginResponse) {
    return {
      type: "LOGINDATA",
      payload: loginResponse,
    };
  } else {
    return {
      type: "LOGINDATA",
      payload: "",
    };
  }
};
