import React, { useState } from "react";
import { arrowimg } from "../Images";
import { convertKBtoMB } from "../../helper/kbToGbConvert";
import ReactAudioPlayer from "react-audio-player";
import moment from "moment/moment";
import BatteryGauge from "react-battery-gauge";
import Loader from "../loader/loader";
import { dateTimeFormat } from "../../helper/dateTimeFormat";
import ReactPlayer from 'react-player'
import { useEffect } from "react";

const RightSidebar = ({ sidebarGetData, postType, customBattery, loading }) => {

  const [date] = useState(moment(new Date()).utc().local().format("YYYY-MM-DD"));
  const [objectUrl, setObjectUrl] = useState(null);
  const [vidObjectUrl, setVidObjectUrl] = useState(null);

  
  // Create Object Url to fix the audio -webkit playback issue
  useEffect(() => {
    if (sidebarGetData?.postMedia && postType === 6) {
      fetch(sidebarGetData?.postMedia?.url)
        .then(response => response.blob())
        .then(audioBlob => {
          const url = URL.createObjectURL(audioBlob);
          setObjectUrl(url);
        })
        .catch(error => {
          console.error('Error fetching audio:', error);
        });
    }
  }, [sidebarGetData, postType]);

  // Create Object Url to fix the video -webkit playback issue
  useEffect(() => {
    if (sidebarGetData?.postMedia && sidebarGetData?.postMedia.length > 0) {
      const fetchPromises = sidebarGetData?.postMedia.map((videoData) => {
        return fetch(videoData.url)
          .then((response) => response.blob())
          .then((videoBlob) => {
            const url = URL.createObjectURL(videoBlob);
            return {
              url,
              duration: videoData.duration,
              fileSize: videoData.fileSize,
              fileType: videoData.fileType,
            };
          })
          .catch((error) => {
            console.error("Error fetching video:", error);
            return null;
          });
      });

      Promise.all(fetchPromises).then((videoUrls) => {
        const validVideoUrls = videoUrls.filter((url) => url !== null);
        setVidObjectUrl(validVideoUrls);
      });
    }
  }, [sidebarGetData]);

  useEffect(() => {
    // Revoke the Object URL when it's no longer needed
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objectUrl]);

  // vidObjectUrl state will be null when loading true
  useEffect(() => {
    if (loading === true) {
      setVidObjectUrl(null);
    }
  }, [loading]);

  return (
    <>
      <div className="right-sidebar bg-outer">
        <div className="sidebar-head">
          <h2 className="title-small mb-0">Detailed View</h2>
        </div>
        <div className="sidebar-body">
          {loading === true ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {sidebarGetData === null ? (
                <div className="default-view text-center">
                  <div className="circular-arrow">
                    <img src={arrowimg} alt="" />
                  </div>
                  <p>Click on an item to view details</p>
                </div>
              ) : (
                <>
                  <div className="commute-view rightsidebar-top">
                    {postType === 1 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Location
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 2 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Date
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 3 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Commute
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 4 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Note
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 5 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Safety Check-In
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 21 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Missed Safety Check-In
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 6 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light">
                        Recording
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 7 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light text-capitalize">
                        {sidebarGetData?.isSOSCancelled === false
                          ? "SOS"
                          : sidebarGetData?.description}
                      </h3>
                    ) : (
                      ""
                    )}
                    {postType === 8 ? (
                      <h3 className="sidebody-heading text-center mb-3 blue-light text-capitalize">
                        {sidebarGetData?.description}
                      </h3>
                    ) : (
                      ""
                    )}

                    {/* Location */}
                    {postType === 1 || postType === 20 ? (
                      <>
                        {postType === 20 ? (
                          <h4>{sidebarGetData?.didEnter === true ? `Arrived at ${sidebarGetData?.locationName}` : `Departed from ${sidebarGetData?.locationName}`}</h4>
                        ) : (
                          <h4>{sidebarGetData?.description}</h4>
                        )}
                        <div className="map-box mb-3">
                          <iframe
                            title="Map"
                            name="gMap"
                            className="w-100"
                            src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></iframe>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-calender"></i>
                            <p className="bold">Date</p>
                            <p className="battery">
                              <BatteryGauge
                                className="me-1"
                                value={sidebarGetData?.batteryPercentage}
                                size={18}
                                customization={customBattery}
                              />
                              <span className="battery-percentage">
                                {sidebarGetData?.batteryPercentage}%
                              </span>
                            </p>
                          </div>
                          <p>
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "MMMM D, yyyy"
                            )}
                          </p>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold me-1">Time</p>
                          </div>
                          <p>
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "h:mm A"
                            )}
                          </p>
                        </div>
                        {sidebarGetData?.locationName ||
                          sidebarGetData?.addressLine1 ||
                          sidebarGetData?.addressLine2 ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-location"></i>
                              <p className="bold me-1">Location</p>
                            </div>
                            <p>
                              <span className="fw-semibold">{postType === 20 ? sidebarGetData?.businessName : sidebarGetData?.locationName}</span><br />
                              {postType === 20 ? (
                                <>
                                  {sidebarGetData?.businessName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                                  {sidebarGetData?.businessName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                                </>
                              )
                                : (
                                  <>
                                    {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                                    {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                                  </>
                                )
                              }
                              {sidebarGetData?.addressLine2 && (
                                <>
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}

                            </p>
                          </div>
                        ) : null}
                        {sidebarGetData?.companions?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-people yellow-icon"></i>
                              <p className="bold">Companions</p>
                            </div>
                            <ul className="companions-list">
                              {sidebarGetData?.companions?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <span className="companions-profile">
                                      {item.name.slice(0, 1)}
                                    </span>
                                    <p>{item.name}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        {sidebarGetData?.sharingWith?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-share"></i>
                              <p className="bold">You're sharing with</p>
                            </div>
                            <ul className="side-profile-list">
                              {sidebarGetData?.sharingWith?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="profile-box">
                                      {item?.userProfilePic ? (
                                        <img
                                          src={item?.url}
                                          alt={item?.userFriendName}
                                        />
                                      ) : (
                                        <div className="avatar-name">
                                          {item?.userFriendName?.slice(0, 1)}
                                        </div>
                                      )}
                                    </div>
                                    <p>
                                      {item?.userFriendName}{" "}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {/* Date */}
                    {postType === 2 ? (
                      <>
                        <h4>{sidebarGetData?.description}</h4>
                        <div className="map-box mb-3">
                          <iframe
                            title="Map"
                            name="gMap"
                            className="w-100"
                            src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></iframe>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold">Date Start</p>
                            <p className="battery">
                              <BatteryGauge
                                className="me-1"
                                value={sidebarGetData?.batteryPercentage}
                                size={18}
                                customization={customBattery}
                              />
                              <span className="battery-percentage">
                                {sidebarGetData?.batteryPercentage}%
                              </span>
                            </p>
                          </div>
                          <p>
                            {dateTimeFormat(sidebarGetData?.datePostStartDate?.slice(0, 10) + "T" + sidebarGetData?.datePostStartTime, "MMMM D, yyyy")}{" "}
                            |{" "}
                            {dateTimeFormat(
                              `${date} ${sidebarGetData?.datePostStartTime}`,
                              "h:mm A"
                            )}
                          </p>
                          <p>
                            <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                            {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                            {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                            {sidebarGetData?.addressLine2 && (
                              <>
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                              </>
                            )}
                          </p>
                        </div>
                        {sidebarGetData?.dateChildPost ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-watch"></i>
                              <p className="bold">Date End</p>
                              <p className="battery">
                                <BatteryGauge
                                  className="me-1"
                                  value={
                                    sidebarGetData?.dateChildPost
                                      ?.batteryPercentage
                                  }
                                  size={18}
                                  customization={customBattery}
                                />
                                <span className="battery-percentage">
                                  {
                                    sidebarGetData?.dateChildPost
                                      ?.batteryPercentage
                                  }
                                  %
                                </span>
                              </p>
                            </div>
                            <p>
                              {dateTimeFormat(sidebarGetData?.dateChildPost?.datePostEndDate?.slice(0, 10) + "T" + sidebarGetData?.dateChildPost?.datePostEndTime, "MMMM D, yyyy")}{" "}
                              |{" "}
                              {dateTimeFormat(
                                `${date} ${sidebarGetData?.dateChildPost?.datePostEndTime}`,
                                "h:mm A"
                              )}
                            </p>

                            <p>
                              <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                              {sidebarGetData?.addressLine2 && (
                                <>
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}
                            </p>
                          </div>
                        ) : null}
                        {sidebarGetData?.datingService ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-heart"></i>
                              <p className="bold me-1">Dating Service</p>
                            </div>
                            <p> {sidebarGetData?.datingService}</p>
                          </div>
                        ) : null}
                        {sidebarGetData?.sharingWith?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-share"></i>
                              <p className="bold">You're sharing with</p>
                            </div>
                            <ul className="side-profile-list">
                              {sidebarGetData?.sharingWith?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="profile-box">
                                      {item?.userProfilePic ? (
                                        <img
                                          src={item?.url}
                                          alt={item?.userFriendName}
                                        />
                                      ) : (
                                        <div className="avatar-name">
                                          {item?.userFriendName?.slice(0, 1)}
                                        </div>
                                      )}
                                    </div>
                                    <p>
                                      {item?.userFriendName}{" "}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-licence"></i>
                            <p className="bold">License plate number</p>
                          </div>
                          {sidebarGetData?.postMedia[0]?.fileName
                            ? (
                              <ul className="side-profile-list">
                                <li>
                                  <div className="license-num mb-1">
                                    <img
                                      src={sidebarGetData?.postMedia[0]?.url}
                                      alt="licence"
                                    />
                                  </div>
                                  {sidebarGetData?.licensePlateNo ? (
                                    <div className="licence-name">{sidebarGetData?.licensePlateNo}</div>) : null}
                                </li>
                              </ul>
                            ) : "NA"}
                        </div>
                      </>
                    ) : null}
                    {/* commute */}
                    {postType === 3 ? (
                      <>
                        <h4>{sidebarGetData?.description}</h4>
                        <div className="map-box mb-3">
                          <iframe
                            title="Map"
                            name="gMap"
                            className="w-100"
                            src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></iframe>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-ride"></i>
                            <p className="bold">Commute type</p>
                          </div>
                          <p>{sidebarGetData?.commuteType}</p>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold">Commute start</p>
                            <p className="battery">
                              <BatteryGauge
                                className="me-1"
                                value={sidebarGetData?.batteryPercentage}
                                size={18}
                                customization={customBattery}
                              />
                              <span className="battery-percentage">
                                {sidebarGetData?.batteryPercentage}%
                              </span>
                            </p>
                          </div>

                          <p>
                            {dateTimeFormat(sidebarGetData?.commutePostStartDate?.slice(0, 10) + "T" + sidebarGetData?.commutePostStartTime, "MMMM D, yyyy")}{" "}
                            |{" "}
                            {dateTimeFormat(
                              `${date} ${sidebarGetData?.commutePostStartTime}`,
                              "h:mm A"
                            )}
                          </p>
                          <p>
                            <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                            {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                            {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                            {sidebarGetData?.addressLine2 && (
                              <>
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                              </>
                            )}
                          </p>
                        </div>
                        {sidebarGetData?.commuteChildPost ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-watch"></i>
                              <p className="bold">Commute end</p>
                              <p className="battery">
                                <BatteryGauge
                                  className="me-1"
                                  value={
                                    sidebarGetData?.commuteChildPost
                                      ?.batteryPercentage
                                  }
                                  size={18}
                                  customization={customBattery}
                                />
                                <span className="battery-percentage">
                                  {
                                    sidebarGetData?.commuteChildPost
                                      ?.batteryPercentage
                                  }
                                  %
                                </span>
                              </p>
                            </div>
                            <p>
                              {dateTimeFormat(sidebarGetData?.commuteChildPost?.commutePostEndDate?.slice(0, 10) + "T" + sidebarGetData?.commuteChildPost?.commutePostEndTime, "MMMM D, yyyy")}{" "}
                              |{" "}
                              {dateTimeFormat(
                                `${date} ${sidebarGetData?.commuteChildPost?.commutePostEndTime}`,
                                "h:mm A"
                              )}
                            </p>
                            <p>
                              <span className="fw-semibold">{sidebarGetData?.commuteChildPost?.locationName}</span><br />
                              {sidebarGetData?.commuteChildPost?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.commuteChildPost?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.commuteChildPost?.addressLine1}
                              {sidebarGetData?.commuteChildPost?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.commuteChildPost?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.commuteChildPost?.addressLine1 === "" ? "" : <br />}
                              {sidebarGetData?.commuteChildPost?.addressLine2 && (
                                <>
                                  {sidebarGetData?.commuteChildPost?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                  {sidebarGetData?.commuteChildPost?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.commuteChildPost?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}
                            </p>
                          </div>
                        ) : null}
                        {sidebarGetData?.companions?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-people yellow-icon"></i>
                              <p className="bold">Companions</p>
                            </div>
                            <ul className="companions-list">
                              {sidebarGetData?.companions?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <span className="companions-profile">
                                      {item.name.slice(0, 1)}
                                    </span>
                                    <p>{item.name}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        {sidebarGetData?.sharingWith?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-share"></i>
                              <p className="bold">You're sharing with</p>
                            </div>
                            <ul className="side-profile-list">
                              {sidebarGetData?.sharingWith?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="profile-box">
                                      {item?.userProfilePic ? (
                                        <img
                                          src={item?.url}
                                          alt={item?.userFriendName}
                                        />
                                      ) : (
                                        <div className="avatar-name">
                                          {item?.userFriendName?.slice(0, 1)}
                                        </div>
                                      )}
                                    </div>
                                    <p>
                                      {item?.userFriendName}{" "}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-licence"></i>
                            <p className="bold">License plate number</p>
                          </div>
                          {sidebarGetData?.postMedia?.fileName
                            ? (
                              <ul className="side-profile-list">
                                <li>
                                  <div className="license-num mb-1">
                                    <img
                                      src={sidebarGetData?.postMedia?.url}
                                      alt="licence"
                                    />
                                  </div>
                                  {sidebarGetData?.licensePlateNo ? (
                                    <div className="licence-name">{sidebarGetData?.licensePlateNo}</div>) : null}
                                </li>
                              </ul>
                            ) : "NA"}
                        </div>
                      </>
                    ) : null}
                    {/* note */}
                    {postType === 4 ? (
                      <>
                        <h4>{sidebarGetData?.description}</h4>
                        {sidebarGetData?.postMedia?.length > 0 ? (
                          <div className="title-box">
                            <p className="bold me-1">Media</p>
                            <ul className="media-file">
                              {vidObjectUrl?.map((vid, index) =>
                                vid?.fileType === "images" ? (
                                  <li key={index}>
                                    <div className="media-list-row">
                                      <div>
                                        <img src={vid?.url} alt="" />
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <li key={index}>
                                    <div className="media-list-row">
                                      <ReactPlayer
                                        controls
                                        playsinline
                                        url={vid?.url}
                                      />
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-calender"></i>
                            <p className="bold">Date</p>
                            <p className="battery">
                              <BatteryGauge
                                className="me-1"
                                value={sidebarGetData?.batteryPercentage}
                                size={18}
                                customization={customBattery}
                              />
                              <span className="battery-percentage">
                                {sidebarGetData?.batteryPercentage}%
                              </span>
                            </p>
                          </div>
                          <p>
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "MMMM D, yyyy"
                            )}
                          </p>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold me-1">Time</p>
                          </div>
                          <p>
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "h:mm A"
                            )}
                          </p>

                        </div>
                        {sidebarGetData?.locationName ||
                          sidebarGetData?.addressLine1 ||
                          sidebarGetData?.addressLine2 ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-location"></i>
                              <p className="bold me-1">Location</p>
                            </div>
                            <p>
                              <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                              {sidebarGetData?.addressLine2 && (
                                <>
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}
                            </p>
                          </div>
                        ) : null}
                        <div className="map-box mb-3">
                          <iframe
                            title="Map"
                            name="gMap"
                            className="w-100"
                            src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></iframe>
                        </div>
                        {sidebarGetData?.companions?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-people yellow-icon"></i>
                              <p className="bold">Companions</p>
                            </div>
                            <ul className="companions-list">
                              {sidebarGetData?.companions?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <span className="companions-profile">
                                      {item?.name?.slice(0, 1)}
                                    </span>
                                    <p>{item?.name}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        {sidebarGetData?.sharingWith?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-share"></i>
                              <p className="bold">You're sharing with</p>
                            </div>
                            <ul className="side-profile-list">
                              {sidebarGetData?.sharingWith?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="profile-box">
                                      {item?.userProfilePic ? (
                                        <img
                                          src={item?.url}
                                          alt={item?.userFriendName}
                                        />
                                      ) : (
                                        <div className="avatar-name">
                                          {item?.userFriendName?.slice(0, 1)}
                                        </div>
                                      )}
                                    </div>
                                    <p>
                                      {item?.userFriendName}{" "}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {/* safetyCheckin */}
                    {postType === 5 || postType === 21 ? (
                      <>
                        <h4>{sidebarGetData?.description}</h4>
                        <div className="map-box mb-3">
                          <iframe
                            title="Map"
                            name="gMap"
                            className="w-100"
                            src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></iframe>
                        </div>
                        {sidebarGetData?.locationName ||
                          sidebarGetData?.addressLine1 ||
                          sidebarGetData?.addressLine2 ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-location"></i>
                              <p className="bold me-1">Location</p>
                            </div>
                            <p>
                              <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                              {sidebarGetData?.addressLine2 && (
                                <>
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}
                            </p>
                          </div>
                        ) : null}
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-calender"></i>
                            <p className="bold">Date</p>
                            <p className="battery">
                              <BatteryGauge
                                className="me-1"
                                value={sidebarGetData?.batteryPercentage}
                                size={18}
                                customization={customBattery}
                              />
                              <span className="battery-percentage">
                                {sidebarGetData?.batteryPercentage}%
                              </span>
                            </p>
                          </div>
                          <p>
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "MMMM D, yyyy"
                            )}
                          </p>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold me-1">Time</p>
                          </div>
                          <p>
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "h:mm A"
                            )}
                          </p>
                        </div>
                        {sidebarGetData?.sharingWith?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-share"></i>
                              <p className="bold">You're sharing with</p>
                            </div>
                            <ul className="side-profile-list">
                              {sidebarGetData?.sharingWith?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="profile-box">
                                      {item?.userProfilePic ? (
                                        <img
                                          src={item?.url}
                                          alt={item?.userFriendName}
                                        />
                                      ) : (
                                        <div className="avatar-name">
                                          {item?.userFriendName?.slice(0, 1)}
                                        </div>
                                      )}
                                    </div>
                                    <p>
                                      {item?.userFriendName}{" "}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {/* Recording */}
                    {postType === 6 ? (
                      <>
                        <h3 className="sidebody-heading text-center mb-3 sky-blue">
                          {sidebarGetData?.description}
                        </h3>
                        {sidebarGetData?.postMedia?.duration !== null && sidebarGetData?.postMedia !== null ? (
                          <>
                            <div className="recording-card mb-3 p-3">
                              {objectUrl &&
                                <ReactAudioPlayer
                                  src={objectUrl}
                                  type="audio/mpeg"
                                  controls={true}
                                  playsinline={true}
                                />
                              }
                            </div>
                            <div className="text-center">
                              <p className="fw-medium">
                                <span className="me-1">
                                  {convertKBtoMB(sidebarGetData?.postMedia?.fileSize)} MB
                                </span>
                                <span>
                                  ({sidebarGetData?.postMedia?.duration})
                                </span>
                              </p>
                            </div>
                          </>
                        ) : null}
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-calender"></i>
                            <p className="bold me-1">Date</p>
                          </div>
                          <p>
                            {sidebarGetData?.isOngoingPost === true ? dateTimeFormat(sidebarGetData?.recordingDate?.slice(0, 10) + "T" + sidebarGetData?.recordingTime, "MMMM D, yyyy")
                              : dateTimeFormat(
                                sidebarGetData?.postUtcDateTime,
                                "MMMM D, yyyy"
                              )}
                          </p>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold me-1">Time</p>
                          </div>
                          <p>
                            {sidebarGetData?.isOngoingPost === true
                              ? dateTimeFormat(
                                `${date} ${sidebarGetData?.recordingTime}`,
                                "h:mm A"
                              )
                              : dateTimeFormat(
                                sidebarGetData?.postUtcDateTime,
                                "h:mm A"
                              )}
                          </p>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            <p className="bold me-1">Duration</p>
                          </div>
                          <p>
                            {sidebarGetData?.postMedia?.duration
                              ? sidebarGetData?.postMedia?.duration
                              : sidebarGetData?.duration}
                          </p>
                        </div>
                        {sidebarGetData?.locationName ||
                          sidebarGetData?.addressLine1 ||
                          sidebarGetData?.addressLine2 ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-location"></i>
                              <p className="bold me-1">Location</p>
                            </div>
                            <p>
                              <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                              {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                              {sidebarGetData?.addressLine2 && (
                                <>
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}
                            </p>
                          </div>
                        ) : null}
                        {sidebarGetData?.postMedia?.duration ? (
                          <div className="map-box mb-3">
                            <iframe
                              title="Map"
                              name="gMap"
                              className="w-100"
                              src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                            ></iframe>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {/* sos */}
                    {(postType === 7 || postType === 8) ? (
                      <>
                        {sidebarGetData?.isSOSCancelled === false &&
                          sidebarGetData?.postMedia?.length > 0 ? (
                          <div className="title-box">
                            <p className="bold me-1">Media</p>
                            <ul className="media-file">
                              {vidObjectUrl?.map((vid, index) =>
                                vid?.fileType === "images" ? (
                                  <li key={index}>
                                    <div className="media-list-row">
                                      <div>
                                        <img src={vid?.url} alt="" />
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <li key={index}>
                                    <div className="media-list-row">
                                      <ReactPlayer
                                        controls
                                        playsinline
                                        url={vid?.url}
                                      />
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                        <div className="map-box mb-3">
                          <iframe
                            title="Map"
                            name="gMap"
                            className="w-100"
                            src={`https://www.google.com/maps/embed/v1/place?q=${sidebarGetData?.lattitude},${sidebarGetData?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                          ></iframe>
                        </div>
                        <div className="title-box">
                          <div className="title-box-head">
                            <i className="icon-watch"></i>
                            {postType === 7 ?
                              <>
                                <p className="bold">
                                  {sidebarGetData?.sosChildPost
                                    ? "Activated SOS"
                                    : "Cancelled SOS"}
                                </p>
                              </>
                              :
                              <>
                                <p className="bold">
                                  {!sidebarGetData?.sosChildPost && "Initialized SOS"}
                                </p>
                              </>
                            }
                            <p className="battery">
                              <BatteryGauge
                                className="me-1"
                                value={sidebarGetData?.batteryPercentage}
                                size={18}
                                customization={customBattery}
                              />
                              <span className="battery-percentage">
                                {sidebarGetData?.batteryPercentage}%
                              </span>
                            </p>
                          </div>
                          <p>
                            {dateTimeFormat(sidebarGetData?.createdAt, "MMMM D, yyyy")}{" "}
                            |{" "}
                            {dateTimeFormat(
                              sidebarGetData?.createdAt,
                              "h:mm A"
                            )}
                          </p>
                          <p>
                            <span className="fw-semibold">{sidebarGetData?.locationName}</span><br />
                            {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.addressLine1}
                            {sidebarGetData?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.addressLine1 === "" ? "" : <br />}
                            {sidebarGetData?.addressLine2 && (
                              <>
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}{","}
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                {sidebarGetData?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                              </>
                            )}
                          </p>
                        </div>
                        {sidebarGetData?.sosChildPost ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-watch"></i>
                              <p className="bold">Deactivated SOS</p>
                              <p className="battery">
                                <BatteryGauge
                                  className="me-1"
                                  value={
                                    sidebarGetData?.sosChildPost
                                      ?.batteryPercentage
                                  }
                                  size={18}
                                  customization={customBattery}
                                />
                                <span className="battery-percentage">
                                  {
                                    sidebarGetData?.sosChildPost
                                      ?.batteryPercentage
                                  }
                                  %
                                </span>
                              </p>
                            </div>
                            <p>
                              {dateTimeFormat(
                                sidebarGetData?.sosChildPost?.createdAt,
                                "MMMM D, yyyy"
                              )}{" "}
                              |{" "}
                              {dateTimeFormat(
                                sidebarGetData?.sosChildPost?.createdAt,
                                "h:mm A"
                              )}
                            </p>
                            <p>
                              <span className="fw-semibold">{sidebarGetData?.sosChildPost?.locationName}</span><br />
                              {sidebarGetData?.sosChildPost?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.sosChildPost?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : sidebarGetData?.sosChildPost?.addressLine1}
                              {sidebarGetData?.sosChildPost?.locationName?.replace(/\s+/g, ' ').trim() === sidebarGetData?.sosChildPost?.addressLine1?.replace(/\s+/g, ' ').trim() || sidebarGetData?.sosChildPost?.addressLine1 === "" ? "" : <br />}
                              {sidebarGetData?.sosChildPost?.addressLine2 && (
                                <>
                                  {sidebarGetData?.sosChildPost?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                  {','}
                                  {sidebarGetData?.sosChildPost?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}<br />
                                  {sidebarGetData?.sosChildPost?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                </>
                              )}
                            </p>
                          </div>
                        ) : null}

                        {sidebarGetData?.isSOSCancelled === false &&
                          sidebarGetData?.sharingWith?.length ? (
                          <div className="title-box">
                            <div className="title-box-head">
                              <i className="icon-share"></i>
                              <p className="bold">You're sharing with</p>
                            </div>
                            <ul className="side-profile-list">
                              {sidebarGetData?.sharingWith?.map(
                                (item, index) => (
                                  <li key={index}>
                                    <div className="profile-box">
                                      {item?.userProfilePic ? (
                                        <img
                                          src={item?.url}
                                          alt={item?.userFriendName}
                                        />
                                      ) : (
                                        <div className="avatar-name">
                                          {item?.userFriendName?.slice(0, 1)}
                                        </div>
                                      )}
                                    </div>
                                    <p>
                                      {item?.userFriendName}{" "}
                                    </p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default RightSidebar;