import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from '../../../Services/middleware';
import { deleteMultiplePost } from "../../../Services/api/endpoints";
import { showToast } from '../../../helper/swal';
const AllFileDelete = ({
  openModal,
  closeModal,
  selectedItem,
  removeDeleted,
  setSidebarData
}) => {
  const [loading, setLoading] = useState(false);
  const deletePost = async () => {
    let data = selectedItem.map((id) => {
      return { id: id };
    });
    try {
      setLoading(true)
      const response = await axiosInstance.delete(deleteMultiplePost, { data });
      let apiResponse = response.data;
      if (apiResponse?.httpStatus === 200 && response.data?.success === true) {
        setLoading(false)
        closeModal();
        removeDeleted();
        setSidebarData(null);
        showToast(apiResponse?.message, "success", false, 4500);
      } else if (response.data?.success === false) {
        setLoading(false)
        closeModal();
        showToast(apiResponse?.message, "error", true);
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        showToast(err.response.data?.message, "error", true);
      }
    }
  };

  return (
    <>
      <Modal
        show={openModal}
        onHide={closeModal}
        centered
      >
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
          <button type='button' className='btn-close-modal' onClick={closeModal}>
            <span className='icon-close'></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-0'>Are you sure you want to delete the selected post?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='btn-large' onClick={deletePost}>
            {loading === true ? (
              <>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <>
                Delete Post
              </>
            )}
          </Button>
          <Button variant="transparent" className='btn-large' onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllFileDelete;