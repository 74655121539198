import axios from "axios";

// for normal environment
const baseURL = process.env.REACT_APP_ENV_VARIABLE_KEY;

const noToken = axios.create({
  baseURL,
  headers: {
    timezone_offset: -330,
    language: "en-US",
    "Content-Type": "application/json",
    // Authorization: localStorage.getItem('adminLoginToken')
  },
});

export default noToken;
