import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import RecycleBin from "../../components/modals/recycle-bin";
import Restore from "../../components/modals/restore";
import RightSidebar from "../../components/sidebar/rightSideBar";
import axiosInstance from "../../Services/middleware";
import { getRecyclePostData } from "../../Services/api/endpoints";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPostCommuteDetails } from "../../Services/api/endpoints";
import { getPostDateDetails } from "../../Services/api/endpoints";
import { getPostRecordingDetails } from "../../Services/api/endpoints";
import { getPostNoteDetails } from "../../Services/api/endpoints";
import { getPostSafetyCheckInDetails } from "../../Services/api/endpoints";
import { getPostSOSDetails } from "../../Services/api/endpoints";
import { getPostLocationDetails } from "../../Services/api/endpoints";
import Delete from "../../components/modals/delete";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { dateTimeFormat } from "../../helper/dateTimeFormat";
import { truncateString } from "../../helper/truncateString";
import { showToast } from "../../helper/swal";

const RecycleBinPage = () => {
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openRecycleBinModal, setOpenRecycleBinModal] = useState(false);
  const [filePostType, setFilePostType] = useState("0");
  const [postType, setPostType] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10);
  const [postList, setPostList] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [sidebarGetData, setSidebarData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [isChecked, setIsChecked] = useState(null);

  const checkUnchedkItem = (e) => {
    if (e.target.checked === true) {
      let dataArr = [...selectedItem];
      setSelectedItem(dataArr);
      dataArr.push(e.target.value);
    } else {
      let freshArray = selectedItem.filter((val) => val !== e.target.value);
      setSelectedItem(freshArray);
    }
  };
  const fetchData = () => {
    setPageNo(pageNo + 1);
  };

  async function postData() {
    try {
      const response = await axiosInstance.get(
        `${getRecyclePostData}/${pageNo}/${pageSize}/${filePostType}`
      );
      const apiData = response.data.data.result;
      setTotalRecord(response.data.data.totalCount);
      if (pageNo === 1) {
        setPostList(apiData);
        // setResponseGet(apiData)
      } else {
        setPostList([...postList, ...apiData]);
      }
    } catch (error) {

      showToast(error.response.data?.message, "error", true);
    }
  }
  useEffect(() => {
    postData();
  }, [filePostType, pageNo]);


  // for right sidebar
  const selectPostId = async (timelinePost) => {
    try {
      setLoading(true);
      if (timelinePost?.type === 1 || timelinePost?.type === 20) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostLocationDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
      if (timelinePost?.type === 2) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostDateDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
      if (timelinePost?.type === 3) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostCommuteDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
      if (timelinePost?.type === 4) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostNoteDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
      if (timelinePost?.type === 5 || timelinePost?.type === 21) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostSafetyCheckInDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
      if (timelinePost?.type === 6) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostRecordingDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
      if (timelinePost?.type === 7 || timelinePost?.type === 8) {
        setPostType(timelinePost?.type);
        const timelineApiResponse = await axiosInstance.get(
          `${getPostSOSDetails}/${timelinePost?.id}`
        );
        const timelineApiData = timelineApiResponse.data.data;
        setSidebarData(timelineApiData);
        setLoading(false);
      }
    } catch (error) {

      showToast(error.response.data?.message, "error", true);
      setSidebarData(null);
      setLoading(false);
    }
  };

  const customBattery = {
    batteryBody: {
      strokeWidth: 4,
      cornerRadius: 6,
      fill: "none",
      strokeColor: "#111",
    },
    batteryCap: {
      fill: "none",
      strokeWidth: 4,
      strokeColor: "#111",
      cornerRadius: 2,
      capToBodyRatio: 0.4,
    },
    batteryMeter: {
      fill: "#090F2C",
      lowBatteryValue: 0,
      lowBatteryFill: "#090F2C",
      outerGap: 4,
      noOfCells: 1,
      interCellsGap: 0,
    },
    readingText: {
      lightContrastColor: "#111",
      darkContrastColor: "#fff",
      lowBatteryColor: "#090F2C",
      fontFamily: "Helvetica",
      fontSize: 14,
      display: "none",
      showPercentage: false,
    },
    chargingFlash: {
      scale: undefined,
      fill: "#090F2C",
      animated: true,
      animationDuration: 1000,
    },
  };

  const removeDeleted = () => {
    setPostList([]);
    setSelectedItem([]);
    setTotalRecord(0);
    setIsChecked(null)
    if (pageNo === 1) {
      postData();
    } else {
      setPageNo(1);
    }
  };

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };
  const tablist = [
    { type: 0, name: "All" },
    { type: 1, name: "Location" },
    { type: 2, name: "Date" },
    { type: 3, name: "Commute" },
    { type: 4, name: "Note" },
    { type: 20, name: "Frequent Location" },
    { type: 5, name: "Safety Check-In" },
    { type: 6, name: "Recordings" },
    { type: 7, name: "SOS" },
  ];
  return (
    <Layout>
      <Row>
        <Col lg={8} md={7} className="mb-4 mb-md-0">
          <div className="bg-outer">
            <div className="head-section">
              <h1 className="title-small">Recycle Bin</h1>
              {selectedItem.length === 0 ? (
                <>
                  {postList && postList?.length ? (
                    <>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-white text-red round btn-icon"
                          onClick={() => setOpenRecycleBinModal(true)}
                        >
                          <span>Empty Recycle Bin</span>
                          <i className="icon-trash"> </i>
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {postList && postList?.length ? (
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-primary round btn-icon"
                        onClick={() => setOpenModal(true)}
                      >
                        <span>Restore</span>
                        <i className="icon-restore"> </i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-white text-red round btn-icon"
                        onClick={() => setDeleteModal(true)}
                      >
                        <span>Delete</span>
                        <i className="icon-trash"> </i>
                      </button>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <div className="body-section">
              <div className="all-files-wrapper">
                <div className="top-tab-list">
                  <Tabs activeTab={activeTab} onTabClick={onTabClick}>
                    {tablist.map((item, index) => (
                      <Tab key={index}>
                        <div className="active radio-btn-group">
                          <label>
                            <input
                              type="radio"
                              name="tag"
                              value={
                                item?.name === "All" ? "0" :
                                  item?.name === "Note" ? "4" :
                                    item?.name === "Commute" ? "3" :
                                      item?.name === "Date" ? "2" :
                                        item?.name === "Location" ? "1" :
                                          item?.name === "Frequent Location" ? "20" :
                                            item?.name === "Safety Check-In" ? "5" :
                                              item?.name === "Recordings" ? "6" :
                                                item?.name === "SOS" ? "7" : ""
                              }
                              onChange={(e) => {
                                setSidebarData(null)
                                setSelectedItem([]);
                                setFilePostType(
                                  `${item?.name === "All" ? "0" :
                                    item?.name === "Note" ? "4" :
                                      item?.name === "Commute" ? "3" :
                                        item?.name === "Date" ? "2" :
                                          item?.name === "Location" ? "1" :
                                            item?.name === "Frequent Location" ? "20" :
                                              item?.name === "Safety Check-In" ? "5" :
                                                item?.name === "Recordings" ? "6" :
                                                  item?.name === "SOS" ? "7" : ""}`
                                );
                                setPageNo(1);
                                setPostList([])
                                setIsChecked(null)
                              }}
                              checked={filePostType === `${item?.type}`}
                            />
                            <span className="btn btn-radio btn-sm">
                              {item?.name}
                            </span>
                          </label>
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
                <div id="scrollablescrll" className="list-group scroller_added">
                  <InfiniteScroll
                    dataLength={postList?.length}
                    next={fetchData}
                    hasMore={postList?.length !== totalRecord}
                    loader={
                      postList?.length ? (
                        <p className="text-center">Loading...</p>
                      ) : null
                    }
                    scrollableTarget="scrollablescrll"
                  >
                    {postList && postList?.length ? (
                      <ul className="list-group recycle-bin-card-wrapper">
                        {postList?.map((data, index) => (
                          <li key={index}>
                            {data?.type === 1 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-location yellow"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {truncateString(data?.description, 20)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 2 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-heart pink"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {truncateString(data?.description, 20)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}
                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 3 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-ride blue"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {truncateString(data?.description, 20)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 4 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-pencil-square sky-green"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {truncateString(data?.description, 20)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 5 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-check green"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {truncateString(data?.description, 20)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 6 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-mic skyblue"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {/* {data?.duration}{" "} */}
                                      {data?.description && truncateString(data?.description, 19)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}
                                  </p>
                                </div>
                              </>
                            )}
                            {(data?.type === 7 || data?.type === 8) && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-sos red"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {(data?.type === 7 && data?.isOngoingPost === false) ? "SOS" : data?.description}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : (" ")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 20 && data?.upcoming === false && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    {data?.freqLocationType === "Work" && <i className='icon-work yellow'></i>}
                                    {data?.freqLocationType === "School" && <i className='icon-school yellow'></i>}
                                    {data?.freqLocationType === "Home" && <i className='icon-house yellow'></i>}
                                    {data?.freqLocationType === "Other1" && <i className='icon-other1 yellow'></i>}
                                    {data?.freqLocationType === "Other2" && <i className='icon-other2 yellow'></i>}
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {data?.didEnter === true ? `Arrived at ${data?.locationName}` : `Departed from ${data?.locationName}`}
                                      {/* {truncateString(data?.description, 20)} */}
                                      {data?.businessName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}
                                      {data?.businessName}
                                      {data?.businessName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.businessName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}

                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {data?.type === 21 && (
                              <>
                                <label className="form-check" htmlFor={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={data.id}
                                    id={index}
                                    onChange={(e) => {
                                      checkUnchedkItem(e);
                                    }}
                                  />
                                </label>
                                <div className="recycle-bin-card">
                                  <label className={isChecked === index ? "card-round active" : "card-round"} htmlFor={'data' + index}>
                                    <i className="icon-close red"></i>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="post"
                                      value={data.id}
                                      id={'data' + index}
                                      onChange={(e) => {
                                        selectPostId(data);
                                        setIsChecked(index)
                                      }}
                                    />
                                    <p>
                                      {truncateString(data?.description, 22)}{" "}
                                      {data?.locationName || data?.addressLine1 || data?.addressLine2 ? (<span>|</span>) : ("")}{" "}
                                      {data?.locationName}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() || data?.addressLine1 === "" ? '' : ', '}
                                      {data?.locationName?.replace(/\s+/g, ' ').trim() === data?.addressLine1?.replace(/\s+/g, ' ').trim() ? "" : data?.addressLine1}
                                      {data?.addressLine2 && (
                                        <>
                                          {', '}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(0, 1)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(1, 3)}
                                          {','}
                                          {data?.addressLine2?.replace(/,/g, ", ").split(",").slice(-1)}
                                        </>
                                      )}
                                    </p>
                                  </label>
                                  <p className="post-date-sty">
                                    {dateTimeFormat(
                                      data?.postUtcTime,
                                      "MMMM D, yyyy"
                                    )}{" "}
                                    |{" "}
                                    {dateTimeFormat(data?.postUtcTime, "h:mm A")}{" "}
                                  </p>
                                </div>
                              </>
                            )}
                            {/* <p>{dateTimeFormat(data?.postUtcTime, 'MMMM D, yyyy')} | {dateTimeFormat(data?.postUtcTime, 'h:mm A')} </p> */}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-center">No Record Found</p>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} md={5}>
          <RightSidebar
            sidebarGetData={sidebarGetData}
            postType={postType}
            customBattery={customBattery}
            loading={loading}
          />
        </Col>
      </Row>
      <Restore
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        selectedItem={selectedItem}
        removeDeleted={() => removeDeleted()}
        setSidebarData={setSidebarData}
      />
      <Delete
        openModal={openDeleteModal}
        closeModal={() => setDeleteModal(false)}
        postData={postData}
        selectedItem={selectedItem}
        removeDeleted={() => removeDeleted()}
        setSidebarData={setSidebarData}
      />
      <RecycleBin
        openRecycleModal={openRecycleBinModal}
        closeRecycleModal={() => setOpenRecycleBinModal(false)}
        postData={postData}
        removeDeleted={() => removeDeleted()}
        setSidebarData={setSidebarData}
      />
    </Layout>
  );
};
export default RecycleBinPage;
