import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import Header from "../../../components/header";
import { resetpasswordPic } from "../../../components/Images";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import axiosInstance from "../../../Services/middleware";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { resetPassword } from "../../../Services/api/endpoints";
import { showToast } from "../../../helper/swal";
import { encryptRequest } from "../../../helper/crypto";
import { useDispatch } from 'react-redux';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("adminLoginToken");
  const urlParams = useLocation().search;
  const getToken = new URLSearchParams(urlParams).get("accessToken");

  // Reset Password Form Initial Values
  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
    hashCode: "",
  }

  // Reset Password Form Validation Schema
  const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required('New password is required').matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      "Password must contain at least 8 characters with 1 upper case, 1 numeric and 1 special characters"
    ),
    confirmNewPassword: Yup.string().required('Confirm New password is required').when("newPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "The New password and confirmation password do not match."
      )
    })
  });

  /**
 * 
 * @param {*} values 
 */
  // Change password form Submit

  const submitHandler = async (values) => {
    setLoading(true)
    const gethashCode = new URLSearchParams(urlParams).get("accessToken");
    let body = {
      newPassword: encryptRequest(values?.newPassword),
      hashCode: gethashCode
    };
    try {
      const response = await axiosInstance.put(resetPassword, body);
      let apiResponse = response.data
      if (apiResponse?.httpStatus === 200 && response.data?.success === true) {
        setLoading(false)

        showToast(apiResponse?.message, "success", false, 4500);
        navigate("/login");
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);

        showToast(error.response.data?.message, "error", true);
      }
    }
  };

  function PasswordField({ name, placeholder }) {
    // password toggle eye
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
      setVisible(!isVisible);
    }

    return (
      <div className="position-relative">
        <Field type={isVisible ? 'text' : 'password'} name={name} className="form-control" placeholder={placeholder} autoComplete="off"></Field>
        <button type="button" className='eye-icons' onClick={() => toggle()}>
          <i className={isVisible ? "icon-eye" : "icon-eye-slash"}></i>
        </button>
      </div>
    )
  }

  useEffect(() => {
    if (getToken) {
      localStorage.clear();
      dispatch({ type: "RESET_STATE" });
    } else {
      navigate('/');
    }
  })

  return (
    <>
      <div className="login-wrapper login-page">
        <Header />
        <Container fluid>
          <Row>
            <Col
              lg={6}
              xs={12}
              className="px-0 banner-image-box d-none d-md-block"
            >
              <div className="banner-image">
                <img className="signup-image" src={resetpasswordPic} alt="logo" />
              </div>
            </Col>
            <Col lg={6} xs={12} className="px-0">
              <div className="form-wrapper">
                <div className="tabs-wrapper">
                  <div className="auth-card">
                    <h1 className="title-3 mb-0">Reset Password?</h1>
                    <Formik initialValues={initialValues} validationSchema={resetPasswordSchema}
                      onSubmit={(values) => {
                        submitHandler(values);
                      }}>
                      {({ errors, touched }) => (
                        <Form>
                          <div className="mb-4 pass-field">
                            <label htmlFor="npassword" className="form-label">
                              New Password
                            </label>
                            <PasswordField name="newPassword" placeholder="New Password" />
                            <ErrorMessage component="div" className="error" name="newPassword" />
                          </div>
                          <div className="mb-4 pass-field">
                            <label htmlFor="cpassword" className="form-label">
                              Confirm New Password
                            </label>
                            <PasswordField name="confirmNewPassword" placeholder="Confirm New Password" />
                            <ErrorMessage component="div" className="error" name="confirmNewPassword" />
                          </div>
                          <div className="text-center mb-3 pt-2">
                            <Button
                              type="submit"
                              className="btn btn-primary btn-large round"
                            >
                              {loading === true ? (
                                <>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <>
                                  Submit
                                </>
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;
