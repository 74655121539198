import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { Link } from 'react-router-dom';
import Logout from '../modals/logout';
import { logoImg } from "../Images";
import axiosInstance from '../../Services/middleware';
import { getCloudStorage } from '../../Services/api/endpoints';
import { convertKBtoGB } from '../../helper/kbToGbConvert';

const LeftSideBar = () => {
  const [openModal, setOpenModal] = useState(false);
  const [storageData, setStorageData] = useState({
    freeSpace: 0,
    totalSpace: 0,
    usedSpace: 0
  });

  async function getStorage() {
    try {
      const response = await axiosInstance.get(
        `${getCloudStorage}`
      );
      const apiResponse = response.data.data;
      if(apiResponse) {
        setStorageData((storageData) => ({
          ...storageData,
          freeSpace: convertKBtoGB(apiResponse.freeSpace),
          usedSpace: convertKBtoGB(apiResponse.usedSpace),
          totalSpace: convertKBtoGB(apiResponse.totalSpace),
        }));
      }

    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    getStorage()
  }, [])

  return (
    <>
      <div className='sidebar-wrapper'>
        <div className='inner-sidebar'>
          <Link to="#" className='navbar-brand cursor-default'>
            <img src={logoImg} alt="Artemizz" />
          </Link>
          <div className='sidebar-content'>
            <ul className="sidebar-menu">
              {/* <li className={
                window.location.pathname.includes("timeline")
                  ? "active"
                  : ""
              }>
                <Link to="/timeline">
                  <i className={
                    window.location.pathname.includes("timeline")
                      ? "icon-timeline_active nav-icon"
                      : "icon-house nav-icon"
                  }></i>
                  <span className="nav-text">Timeline</span>
                </Link>
              </li> */}
              <li className={
                window.location.pathname.includes("all-files")
                  ? "active"
                  : ""
              }>
                <Link to="/all-files">
                  <i className={
                    window.location.pathname.includes("all-files")
                      ? "icon-folder_active nav-icon"
                      : "icon-folder nav-icon"
                  }></i>
                  <span className="nav-text">All Files</span>
                </Link>
              </li>

              <li className={
                window.location.pathname.includes("recycle-bin")
                  ? "active"
                  : ""
              }>
                <Link to="/recycle-bin">
                  <i className={
                    window.location.pathname.includes("recycle-bin")
                      ? "icon-recycle_bin_active nav-icon"
                      : "icon-trash nav-icon"
                  }></i>
                  <span className="nav-text">Recycle Bin</span>
                </Link>
              </li>
            </ul>
            <div className='meter-side-box mb-2'>
              <button className='btn transparent-btn round'>My Cloud</button>
              <div className='meter-round-box'>
                <div className='meter-box'>
                  {storageData.totalSpace > 0 ?
                    <>
                      <ReactSpeedometer
                        fluidWidth={true}
                        value={storageData.usedSpace > storageData.totalSpace ? storageData.totalSpace : storageData.usedSpace}
                        maxValue={storageData.totalSpace}
                        needleHeightRatio={0.55}
                        // segments={2}
                        maxSegmentLabels={2}
                        customSegmentStops={[0, storageData.usedSpace > storageData.totalSpace ? storageData.totalSpace : storageData.usedSpace, storageData.totalSpace]}
                        ringWidth={30}
                        textColor="#333333"
                        segmentColors={[
                          "#5370FD",
                          "#b4c0fd",
                        ]}
                        needleColor="#cccccc"
                        currentValueText={`${storageData.usedSpace > storageData.totalSpace ? `${storageData.totalSpace} GB` : `${storageData.usedSpace} GB`}`}
                        valueTextFontSize={'1.3rem'}
                        needleTransitionDuration={4000}
                        needleTransition="easeElastic"
                      />
                    </>
                    :
                    <div className='meter-loader'>Loading</div>
                  }
                </div>
              </div>
              <div>
                <p>
                  Total Storage: <span>{storageData.totalSpace} GB</span>
                </p>
                <p>
                  Used Storage: <span>{storageData.usedSpace} GB</span>
                </p>
                <p>
                  Free Storage: <span>{storageData.freeSpace} GB</span>
                </p>
              </div>
              {/* <p>Upgrade your plan and get 00 GB</p>
              <button type='button' className='btn btn-yellow round'>Upgrade Plan</button> */}
            </div>
          </div>
          <div className='logout-btn-box'> <button type='button' className='btn logout-btn' onClick={() => setOpenModal(true)}><i className="icon-logout"></i> Logout</button></div>
        </div>
      </div>
      <Logout openModal={openModal} closeModal={() => setOpenModal(false)} />
    </>
  );
};

export default LeftSideBar;