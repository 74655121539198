import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../../components/header';
import { signupImg, appleImg } from '../../../components/Images';

const SignUp = () => {
  const token = localStorage.getItem("adminLoginToken");
  useEffect(() => {
    if (token) {
      window.history.back();
    }
  })
  return (
    <>
      <div className="login-wrapper signup-page" >
        <Header />
        <Container fluid>
          <Row>
            <Col lg={6} xs={12} className="px-0 banner-image-box d-none d-md-block">
              <div className="banner-image">
                <img className='signup-image' src={signupImg} alt="Artemizz" />
              </div>
            </Col>
            <Col lg={6} xs={12} className="px-0">
              <div className='form-wrapper'>
                <div className='tabs-wrapper'>
                  <div className='mb-4 tab-link-wrapper'>
                    <Link to="/sign-up" className='btn tab-link active'>Sign Up</Link>
                    <Link to="/login" className='btn tab-link '>Sign In</Link>
                  </div>
                  <div className='auth-card'>
                    <div className='signup-wrapper'>
                      <div className='join-text'>
                        <p>Join the</p>
                        <h4>Artemizz family</h4>
                        <p>today</p>
                      </div>
                      <a href="https://apps.apple.com/us/app/artemizz/id1642861256"
                        target="_blank"
                        rel="noopener noreferrer"
                        className='btn btn-primary btn-medium round btn-ios-download mx-auto'>
                        <img src={appleImg} alt="App store" />
                        <p className='mb-0'>
                          <span className='small-text'>Download On The</span>
                          <span>App Store</span>
                        </p>
                      </a>
                      {/* <p className='text-center'><span>or</span></p> */}
                      {/* <div className='qr-code'>
                        <img src={QrCode} alt="QR code" />
                      </div>
                      <p className='text-center'><span>Scan to download app</span></p> */}

                    </div>
                  </div>
                </div>

              </div>
              {/* <button onClick={handleClick}>b tnm</button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SignUp;