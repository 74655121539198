import { Routes, Route } from "react-router-dom";
// import Timeline from "../pages/timeline";
import PrivateRoute from "./PrivateRoutes";
import RecycleBinPage from "../pages/RecycleBin";
import ForgotPassword from "../pages/auth/forgotPassword/index";
import LoginForm from "../pages/auth/login/index";
import SignUp from "../pages/auth/signup/index";
import Homepage from "../pages/homepage/Homepage";
import AllFiles from "../pages/allFiles";
import TermsConditions from "../pages/privacyPages/TermsConditions";
import PrivacyPolicy from "../pages/privacyPages/PrivacyPolicy";
import Security from "../pages/privacyPages/Security";
import ResetPassword from "../pages/auth/resetPassword";
import Faq from "../pages/privacyPages/faq";

const BaseRoutes = () => {

  return (
    <>

      <Routes>
        <Route path="/" element={<Homepage />} />

        {/* <Route
          path="/timeline"
          element={
            <PrivateRoute>
              <Timeline />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/all-files"
          element={
            <PrivateRoute>
              <AllFiles />
            </PrivateRoute>
          }
        />

        <Route
          path="/recycle-bin"
          element={
            <PrivateRoute>
              <RecycleBinPage />
            </PrivateRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/security" element={<Security />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </>
  );
};

export default BaseRoutes;
