export const convertKBtoGB = (kb) => {
  const bytes = kb * 1024;
  const gigabytes = bytes / (1024 * 1024 * 1024);
  return parseFloat(gigabytes.toFixed(2));
}


// KB to MB
export const convertKBtoMB = (kb) => {
  return (kb / 1024).toFixed(2);
}