import * as CryptoJS from "crypto-js";

let key = "&**&^@#$%^&*())(*&DCVbhjkFCVFGBH";
let iv = "#$%^&)(*&$%^&@#$";

// Encrypt Data
export function encryptRequest(text) {
  let keyWA = CryptoJS.enc.Utf8.parse(key);
  let ivWA = CryptoJS.enc.Utf8.parse(iv);
  let encryptedDataCP = CryptoJS.AES.encrypt(text, keyWA, { iv: ivWA });
  let encryptedWA = encryptedDataCP.ciphertext.toString(CryptoJS.enc.Hex);
  return encryptedWA;
}

// DecryptData
export function decryptRequest(text) {
  let encryptedWA = CryptoJS.enc.Hex.parse(text);
  let keyWA = CryptoJS.enc.Utf8.parse(key);
  let ivWA = CryptoJS.enc.Utf8.parse(iv);
  let encryptedDataCP = { ciphertext: encryptedWA };
  let decryptedWA = CryptoJS.AES.decrypt(encryptedDataCP, keyWA, { iv: ivWA });
  let decryptedData = decryptedWA.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}