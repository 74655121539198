import { combineReducers } from "redux";
import { adminLoginData } from "./loginReducer";
import { forgotPassword } from "./forgotPasswordReducer";

const combineReducer = combineReducers({
  adminLoginData,
  forgotPassword,
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    delete state.adminLoginData
    delete state.forgotPassword
  }
  return combineReducer(state, action);
};
export default rootReducer;

