import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import Header from '../../../components/header';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginAdminPanel } from "../../../actions/loginAction";
import { loginImg } from '../../../components/Images';
import { showToast } from "../../../helper/swal";
import Cookies from 'universal-cookie';
import { decryptRequest, encryptRequest } from "../../../helper/crypto";

const LoginForm = () => {
  const [isRemember, setIsRemember] = useState(false);
  const [isChecked, setIsChecked] = useState("phone");

  const { loginData } = useSelector((state) => state.adminLoginData);
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };


  const initialValues = {
    email: `${cookies.get('userEmail') && cookies.get('userEmail') !== undefined ? decryptRequest(cookies.get('userEmail')) : ""}`,
    password: `${cookies.get('userPass') && cookies.get('userPass') !== undefined ? decryptRequest(cookies.get('userPass')) : ""}`,
    contactNumber: `${cookies.get('userPhone') && cookies.get('userPhone') !== undefined ? decryptRequest(cookies.get('userPhone')) : ""}`,
    deviceType: 2
  };
  const logInSchema = Yup.object().shape({
    email: isChecked === "email" ? Yup.string()
      .email("Please enter a valid email id")
      .required("Email is required.") : Yup.string(),
    contactNumber: isChecked === "phone" ? Yup.string().min(5, "Please enter a valid phone number").required("Phone Number is required.") : Yup.string(),
    password: Yup.string().required("Password is required."),
  });

  const submitHandler = (values) => {
    // const obj = {
    //   email: values?.email,
    //   password: values?.password,
    // };
    setLoading(true)
    if (isChecked === "phone") {
      const data = { contactNumber: values?.contactNumber, password: values?.password, deviceType: 2 }
      dispatch(loginAdminPanel(data));
    } else {
      const data = { email: values?.email, password: values?.password, deviceType: 2 }
      dispatch(loginAdminPanel(data));
    }


    if (isRemember) {
      cookies.set('userEmail', encryptRequest(values?.email), {
        path: '/',
        maxAge: 7 * 24 * 60 * 60,
        sameSite: true
      });
      cookies.set('userPhone', encryptRequest(values?.contactNumber), {
        path: '/',
        maxAge: 7 * 24 * 60 * 60,
        sameSite: true
      });
      cookies.set('userPass', encryptRequest(values?.password), {
        path: '/',
        maxAge: 7 * 24 * 60 * 60,
        sameSite: true
      });
      cookies.set('checked', "Yes", {
        path: '/',
        maxAge: 7 * 24 * 60 * 60,
        sameSite: true
      });
    } else {
      cookies.set('userEmail', "", { path: '/' });
      cookies.set('userPhone', "", { path: '/' });
      cookies.set('userPass', "", { path: '/' });
      cookies.set('checked', "No", { path: '/' });
    }

    // Remember me
    if (cookies.get('checked') === "Yes" && cookies.get('checked') !== undefined) {
      setIsRemember(true);
    } else {
      setIsRemember(false);
    }
  };

  useEffect(() => {
    const { data, httpStatus, message, success } = loginData;

    if (httpStatus === 200 && success === true) {
      if (!localStorage.getItem("adminLoginToken")) {
        localStorage.setItem("adminLoginToken", data.accessToken);
      }

      showToast(message, "success", false, 4500);
      // navigate("/timeline");
      navigate("/all-files");
    } else if (success === false) {
      setLoading(false);

      showToast(message, "error", true);
      dispatch({ type: "RESET_STATE" });
      navigate("/login");
    }

  }, [loginData, navigate, dispatch]);

  return (
    <>
      <div className="login-wrapper login-page" >
        <Header />
        <Container fluid>
          <Row>
            <Col lg={6} xs={12} className="px-0 banner-image-box d-none d-md-block">
              <div className="banner-image">
                <img className='login-image' src={loginImg} alt="logo" />
              </div>
            </Col>
            <Col lg={6} xs={12} className="px-0">
              <div className='form-wrapper'>
                <div className='tabs-wrapper'>
                  <div className='mb-4 tab-link-wrapper'>
                    <Link to="/sign-up" className='btn tab-link'>Sign Up</Link>
                    <Link to="/login" className='btn tab-link active'>Sign In</Link>
                  </div>

                  <div className="auth-card">
                    <div className="d-flex">
                      <label className="custom-radio-btn me-3">
                        <input type="radio" name="login" checked={isChecked === "phone"} onChange={() => setIsChecked("phone")} />
                        <span className="check"></span>
                        <span className="form-label">Sign In by Phone Number</span>
                      </label>
                      <label className="custom-radio-btn">
                        <input type="radio" name="login" checked={isChecked === "email"} onChange={() => setIsChecked("email")} />
                        <span className="check"></span>
                        <span className="form-label">Sign In by Email</span>
                      </label>
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={logInSchema}
                      onSubmit={(values) => {
                        // same shape as initial values
                        submitHandler(values);
                      }}
                    >
                      {({ handleBlur, errors, touched, setFieldValue, values, handleSubmit }) => (
                        <Form>
                          {isChecked === "phone" ? (
                            <div className="mb-4">
                              <label htmlFor="contactNumber" className="form-label">
                                Phone number
                              </label>
                              <input
                                value={values?.contactNumber}
                                autoComplete="off"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("contactNumber", e.target.value);
                                }}
                                type="number"
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                className="form-control"
                                id="contactNumber"
                                placeholder="Enter your Phone Number"
                              />
                              {touched?.contactNumber && errors?.contactNumber && (
                                <span className="error">{errors?.contactNumber}</span>
                              )}
                            </div>

                          ) : (
                            <div className="mb-4">
                              <label htmlFor="email" className="form-label">
                                Email address
                              </label>
                              <input
                                value={values?.email}
                                onBlur={handleBlur}
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue("email", e.target.value);
                                }}
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter your Email"
                              />
                              {touched?.email && errors?.email && (
                                <span className="error">{errors?.email}</span>
                              )}
                            </div>
                          )}
                          <div className="mb-3">
                            <label htmlFor="pass" className="form-label">
                              Password
                            </label>
                            <div className="pass-field">
                              <div className="position-relative">
                                <input
                                  type={passwordType}
                                  onBlur={handleBlur}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setFieldValue("password", e.target.value);
                                  }}
                                  value={values?.password}
                                  name="password"
                                  className="form-control"
                                  placeholder="Enter your Password"
                                />
                                {/* <i className='icon-eye-slash'></i> */}
                                <button type="button" onClick={togglePassword}>
                                  {passwordType === "password" ? (
                                    <i className="icon-eye-slash"></i>
                                  ) : (
                                    <i className="icon-eye"></i>
                                  )}
                                </button>
                              </div>
                              {touched?.password && errors?.password && (
                                <span className="error">{errors?.password}</span>
                              )}
                            </div>
                          </div>
                          <div className="remember-me mb-4">
                            <div className="form-check">
                              <input
                                id="remember-me"
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                checked={isRemember}
                                onChange={(e) => { setIsRemember(!isRemember) }}
                              />
                              <label className="form-check-label" htmlFor="remember-me">
                                Remember Me
                              </label>
                            </div>

                            <Link className="forgot-pass" to="/forgot-password" >
                              Forgot Password?
                            </Link>
                          </div>
                          <div className="text-center">
                            <Button
                              type="button"
                              className="btn btn-primary btn-large round"
                              onClick={handleSubmit}
                            >
                              {loading === true ? (
                                <>
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>

                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <div>Sign In</div>
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

              </div>
              {/* <button onClick={handleClick}>b tnm</button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LoginForm;
