import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../../helper/swal';
import { logoutUser } from '../../../Services/api/endpoints';
import axiosInstance from '../../../Services/middleware';

const Logout = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const logoutHandle = async (event) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(logoutUser);
      let responseData = response.data;
      if (responseData?.httpStatus === 200 && response.data?.success === true) {
        setLoading(false)
        closeModal()

        showToast(responseData?.message, "success", false, 4500);
        dispatch({ type: "RESET_STATE" });
        localStorage.clear();
        navigate('/login')
      }
    } catch (error) {
      setLoading(false)
      if (error.response) {

        showToast(error.response.data?.message, "error", true);
      }
    }
  };
  return (
    <>
      <Modal
        show={openModal}
        onHide={closeModal}
        centered
      >
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
          <button type='button' className='btn-close-modal' onClick={closeModal}>
            <span className='icon-close'></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-0'>Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='btn-large' onClick={() => logoutHandle()}>
            {loading === true ? (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>

                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <div>Logout</div>
            )}
          </Button>
          <Button variant="transparent" className='btn-large' onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Logout;