import {
  BrowserRouter, Navigate,
} from "react-router-dom";
import BaseRoutes from './Routes/BaseRoutes';
import { useDispatch } from 'react-redux';
import './App.scss';
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const handleStorageEvent = (event) => {
    if (event.key === null && event.storageArea === localStorage) {
      dispatch({ type: "RESET_STATE" });
      Navigate("/");
    }
  };
  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);
  return (
    <div>
      <BrowserRouter>
        <BaseRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
