export const getUserProfileDetail = "/api/Users/UserProfileDetail";
export const updateUserProfileDetail = "/api/Users/UpdateUserProfileDetail";
export const updateUserProfilePic = "/api/Users/UpdateUserProfileImage";
export const changePassword = "api/Users/ChangePassword";
export const forgotPassword = "api/Users/forgotPassword";
export const resetPassword = "api/ManageUsers/SaveResetPassword";
export const getPostData = "api/Posts/PostList";
export const getUserTimeline = "api/Posts/GetPost";
export const getRecyclePostData = "api/Posts/RecyclePostList";

export const getPostCommuteDetails = "api/Posts/GetCommutePostDetails";
export const getPostDateDetails = "api/Posts/GetDatePostDetails";
export const getPostRecordingDetails = "api/Posts/GetRecordingDetails";
export const getPostNoteDetails = "api/Posts/GetNotePostDetails";
export const getPostSafetyCheckInDetails = "api/Posts/SafetyCheckInPostDetails";
export const getPostSOSDetails = "api/SOS/GetSOSPostDetails";
export const getPostLocationDetails = "api/Posts/GetLocationCheckInPostDetails";
export const getRestoreDeletedPosts = "api/Posts/RestoreDeletedPosts";
export const getRemoveRecyclebinPosts = "api/Posts/RemoveRecyclebinPosts";
export const getDeletePost = "api/Posts/DeletePost";
export const deleteMultiplePost = "api/Posts/DeleteMultiplePost";
export const getDownloadTimeLine = "api/Posts/DownloadTimeLine";
export const emailVerify = "api/Users/SendEmailVerification";
export const logoutUser = "api/Users/Logout";
export const cmsList = "api/CMS/CMSList";
export const faqList = "api/FAQ/FaqList";
export const getCredentials = "api/AzureManager/Credentials";
export const getCloudStorage = "api/Posts/StorageUsed";



