import React from "react";
import { Container } from "react-bootstrap";
import { logoImg } from "../../components/Images";
import { Link } from "react-router-dom";
import FaqPage from "../../components/faq";

const Faq = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <header className="header-wrapper privacy-header">
        <Link to="/" className="navbar-brand">
          <img src={logoImg} className="nav-light-logo" alt="Artemizz" />
        </Link>
      </header>
      <section className="faq-content">
        <Container className="privacy-content">
          <section className="faq" id="faq">
            <FaqPage />
          </section>
        </Container>
      </section>
    </>
  );
};

export default Faq;
