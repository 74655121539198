import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { showToast } from '../../../helper/swal';
import { getRemoveRecyclebinPosts } from '../../../Services/api/endpoints';
import axiosInstance from '../../../Services/middleware';

const RecycleBin = ({ openRecycleModal, closeRecycleModal, removeDeleted, setSidebarData }) => {
  const [isState, setIsState] = useState(true);
  const [loading, setLoading] = useState(false);

  const deleteAllPost = async () => {
    try {
      setLoading(true)
      let data = [{ id: 0 }]
      const response = await axiosInstance.delete(
        `${getRemoveRecyclebinPosts}/${isState}`, { data }
      );
      let apiResponse = response.data;
      if (apiResponse?.httpStatus === 200 && apiResponse?.success === true) {
        setLoading(false)
        closeRecycleModal();
        removeDeleted(data);
        setSidebarData(null);

        showToast(apiResponse?.message, "success", false, 4500);
      } else if (apiResponse?.success === false) {
        setLoading(false)
        closeRecycleModal();

        showToast(apiResponse?.message, "error", true);
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)

        showToast(err.response.data?.message, "error", true);
      }
    }
  };
  return (
    <>
      <Modal
        show={openRecycleModal}
        onHide={closeRecycleModal}
        centered
      >
        <Modal.Header>
          <Modal.Title>Empty Recycle Bin</Modal.Title>
          <button type='button' className='btn-close-modal' onClick={closeRecycleModal}>
            <span className='icon-close'></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-0'>Are you sure you want to permanently delete these post?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='btn-large' onClick={deleteAllPost}>
            {loading === true ? (
              <>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <>
                Delete Post Permanently
              </>
            )}
          </Button>
          <Button variant="transparent" className='btn-large' onClick={closeRecycleModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RecycleBin;