import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import axiosInstance from "../../../Services/middleware";
import validator from "validator";
import {
  emailVerify,
  getUserProfileDetail,
  updateUserProfilePic,
  getCredentials,
  updateUserProfileDetail
} from "../../../Services/api/endpoints";
// import { dateTimeFormat } from "../../../helper/dateTimeFormat";
import { formatPhoneNumber } from "../../../helper/phoneNumberFormat";
import { BlobServiceClient } from "@azure/storage-blob";
import { showToast } from "../../../helper/swal";
import { decryptRequest, encryptRequest } from "../../../helper/crypto";

const EditProfile = ({ handleClick, openEditProfileeModal, closeEditProfileModal, fetchProfileData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    id: "",
    userId: "",
    fName: "",
    lName: "",
    email: "",
    gender: "",
    phone: "",
    cityName: "",
    state: "",
    dob: "",
    isEmailVerified: "",
    sosDefaultTime: "",
    profilePic: "",
    url: "",
  });
  useEffect(() => {
    async function userProfileData() {
      try {
        const response = await axiosInstance.get(
          `${getUserProfileDetail}`
        );

        const apiData = response.data.data;
        setProfileData({
          ...profileData,
          id: apiData?.id,
          userId: apiData?.userId,
          fName: decryptRequest(apiData?.fName),
          lName: decryptRequest(apiData?.lName),
          email: decryptRequest(apiData?.email),
          gender: apiData?.gender,
          phone: decryptRequest(apiData?.phone),
          cityName: apiData?.cityName,
          state: apiData?.state,
          dob: moment(apiData?.dob).toDate(),
          // validDob: moment.utc(apiData?.validDob).toDate(),
          isEmailVerified: apiData?.isEmailVerified,
          sosDefaultTime: apiData?.sosDefaultTime,
          profilePic: apiData?.profilePic,
          url: apiData?.url
        });
        setFile(apiData?.url)
      } catch (error) {
        if (error.response) {
          setLoading(false);
          showToast(error.response.data?.message, "error", true);
        }
      }
    }

    userProfileData();
  }, []);


  const [fName, setfName] = useState(true);
  const [lName, setlName] = useState(true);
  const [email, setEmail] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [phone, setPhone] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [dob, setDob] = useState(true);

  const [creds, setCreds] = useState(null);
  const [fileAzure, setFileAzure] = useState(null);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  // const [validDob, setValidDob] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const onEditProfileChange = (name) => (event) => {
    let target = event.target;
    let inputName = target.name;
    let value = target.value;
    if (name !== "dob") {
      setProfileData({ ...profileData, [name]: event.target.value });
    }

    if (inputName === "fName" && validator.isEmpty(value)) {
      setfName(false);
    } else if (name === "fName" && value && !validator.isEmpty(value)) {
      setfName(true);
    }

    if (inputName === "lName" && validator.isEmpty(value)) {
      setlName(false);
    } else if (name === "lName" && value && !validator.isEmpty(value)) {
      setlName(true);
    }

    if (inputName === "phone" && validator.isEmpty(value)) {
      setPhone(false);
      setValidPhone(true);
    } else if (name === "phone" && value && !validator.isEmpty(value)) {
      setPhone(true);
      if (value?.length < 5) {
        setValidPhone(false);
      } else {
        setValidPhone(true);
      }
    }

    if (inputName === "email" && validator.isEmail(value)) {
      setEmail(value);
      setValidEmail(true);
    } else if (inputName === "email" && value && !validator.isEmail(value)) {
      setEmail(value);
      setValidEmail(false);
    } else if (inputName === "email" && validator.isEmpty(value)) {
      setValidEmail(false);
    }
  };

  function handleChange(event) {
    setProfileData({ ...profileData, dob: event })
    if (event === null) {
      setDob(false);
      setDisableButton(true);
    } else {
      setDob(true);
      setDisableButton(false);
    }
  }

  useEffect(() => {
    if (fName === false || lName === false || validEmail === false || phone === false || validPhone === false || dob === false) {
      setDisableButton(true);
    } else if (fName === true && lName === true && validEmail === true && phone === true && validPhone === true && dob === true) {
      setDisableButton(false);
    }
  }, [validEmail, fName, lName, phone, validPhone, dob]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    let input = {
      id: profileData?.id,
      userId: profileData?.id,
      fName: encryptRequest(profileData?.fName),
      lName: encryptRequest(profileData?.lName),
      email: encryptRequest(profileData?.email),
      gender: profileData?.gender,
      phone: encryptRequest(profileData?.phone),
      cityName: profileData?.cityName,
      state: profileData?.state,
      dob: moment.utc(profileData?.dob).local().format('MM/DD/YYYY'),
    };
    try {
      const response = await axiosInstance.put(updateUserProfileDetail, input);
      let apiResponse = response.data
      if (apiResponse?.httpStatus === 200 && response.data?.success === true) {
        showToast(apiResponse?.message, "success", false, 4500);
        closeEditProfileModal(true)
        fetchProfileData()
        setLoading(false);
        handleClick()
        dispatch({ type: 'UPDATEUSER', payload: input });

      } else if (response.data?.success === false) {
        setLoading(false);
        showToast(apiResponse?.message, "error", true);
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        showToast(error.response.data?.message, "error", true);
      }
    }
  };

  const [sendEmailVerify, setSendEmailVerify] = useState(false)
  const onVerifyEmail = async (event) => {
    let input = {
      email: encryptRequest(profileData?.email)
    };
    try {
      const response = await axiosInstance.post(emailVerify, input);
      let responseData = response.data;
      if (responseData?.httpStatus === 200 && response.data?.success === true) {
        setSendEmailVerify(true)

        showToast(responseData?.message, "success", false, 4500);
      }
      if (responseData?.httpStatus === 200 && response.data?.success === false) {
        setSendEmailVerify(true)
        showToast(responseData?.message, "error", false, 4500);
      }
    } catch (error) {
      if (error.response) {

        showToast(error.response.data?.message, "error", true);
      }
    }
  };


  // Update User profile pic
  // const [profilePic, setProfilePic] = useState({
  //   userId: "",
  //   profilePic: ""
  // })

  // useEffect(() => {
  //   async function userProfilePic() {
  //     try {
  //       const response = await axiosInstance.put(
  //         `${updateUserProfilePic}`
  //       );
  //       const responseData = response.data.data;
  //       console.log(responseData, 'responseDataresponseData');

  //       setProfilePic({
  //         ...profilePic,
  //         userId: responseData?.userId,
  //         profilePic: responseData?.profilePic,
  //       })
  //     }
  //     catch (err) {
  //     }
  //   }
  //   userProfilePic();
  // }, [])

  // const onProfileClick = async (event) => {
  //   //event.preventDefault();
  //   let fileInput = {
  //     userId: responseGet?.id,
  //     profilePic: profilePic?.profilePic,
  //   };
  // }


  // profile pic
  async function getCreds() {
    try {
      const response = await axiosInstance.get(`${getCredentials}`);
      const apiData = response.data.data;
      if (apiData) {
        setCreds(apiData);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  useEffect(() => {
    getCreds();
  }, [])
  // const handleUpload = async (files) => {
  //   if (files) {
  //     const blobService = new BlobServiceClient(
  //       `${creds.azureBlobURL}/?${creds.connectionString}`
  //     );
  //     const containerClient = blobService.getContainerClient(
  //       creds.containerName
  //     );
  //     const blobClient = containerClient.getBlockBlobClient(files.name);
  //     const options = { blobHTTPHeaders: { blobContentType: files.type } };
  //     const response = await blobClient.uploadBrowserData(files, options);
  //     setUrl(response);
  //   }
  // };
  const handleUpload = async (files) => {
    // debugger
    if (files) {
      // const blobService = new BlobServiceClient(
      //   `${creds.azureBlobURL}/?${creds.connectionString}`
      // );
      // const containerClient = blobService.getContainerClient(
      //   creds.containerName
      // );

      // const blobClient = containerClient.getBlockBlobClient(files.name);
      // const options = { blobHTTPHeaders: { blobContentType: files.type } };
      // const response = await blobClient.uploadBrowserData(files, options);
      // setUrl(response);

      // Set your storage account name, container name, and SAS token here
      const blobServiceClient = new BlobServiceClient(creds.azureBlobURL);
      const containerName = creds.containerName;

      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const blobName = files.name;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      try {
        const response = await blockBlobClient.uploadBrowserData(files);
        console.log("File uploaded successfully", response);
        setUrl(response);
      } catch (error) {
        console.log("Error uploading file", error);
      }
    }
  };
  const handleFileChange = (event) => {
    console.log(event);
    setFile(URL.createObjectURL(event.target.files[0]));
    setProfileData({ ...profileData, profilePic: event.target.files[0].name });
    setFileAzure(event.target.files[0]);
    handleUpload(event.target.files[0]);
  };
  return (
    <>
      <Modal
        show={openEditProfileeModal}
        onHide={closeEditProfileModal}
        centered

      >
        <Modal.Header>
          <Modal.Title>Edit Personal Details</Modal.Title>
          <button
            type="button"
            className="btn-close-modal"
            onClick={closeEditProfileModal}
          >
            <i className="icon-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form className="profile-max-wid">
            <div className="mb-3">
              <div className="profile-image">
                <div className="update-image">
                  {profileData?.profilePic === "" || profileData?.profilePic === null
                    ?
                    <div className='avatar-name'>
                      {profileData?.fName.slice(0, 1)}
                    </div>
                    :
                    <img src={file} alt={profileData?.fName} />
                  }
                </div>
                {/* <label className="edit-icon" htmlFor="fileupload">
                  <i className="icon-camera"></i>
                  <input
                    type="file"
                    name="profilePic"
                    id="fileupload"
                    className="file-upload"
                    value={""}
                    accept="image/*"
                    // onChange={onEditProfileChange("profilePic")}
                    onChange={handleFileChange}
                  />
                </label> */}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="fName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="fName"
                autoComplete="off"
                value={profileData?.fName}
                name="fName"
                onChange={onEditProfileChange("fName")}
              />
              {fName === false ? (
                <div className="error">name is required</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="lName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lName"
                autoComplete="off"
                value={profileData?.lName}
                name="lName"
                onChange={onEditProfileChange("lName")}
              />
              {lName === false ? (
                <div className="error">Last name is required</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                //onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                //value={profileData?.phone}
                value={formatPhoneNumber(profileData?.phone)}
                // value = target.value;
                autoComplete="off"
                name="phone"
                disabled={true}
                onChange={onEditProfileChange("phone")}
              />
              {phone === false ? (
                <div className="error">Phone number is required</div>
              ) : null}
              {validPhone === false ? (
                <div className="error">Min 5 digits required</div>
              ) : null}

            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                value={profileData?.email}
                autoComplete="off"
                name="email"
                onChange={onEditProfileChange("email")}
                disabled={profileData?.isEmailVerified === true ? true : false}
              />
              {validEmail === false ? (
                <div className="error">Please enter valid email id</div>
              ) : null}
              {(sendEmailVerify === false && profileData?.isEmailVerified === false) ?
                <div className="primary-link mt-1 text-end" onClick={onVerifyEmail}>Verify Email</div>
                :
                null
              }
            </div>
            <div className="mb-3">
              <label htmlFor="birthday" className="form-label">
                Birthday
              </label>
              {/* <input type="text" className="form-control" id="birthday" value={loginData.data?.dob} readonly /> */}
              <DatePicker
                className="form-control"
                selected={(profileData?.dob)}
                dateFormat="MMMM d, yyyy"
                // onChange={(date) => setStartDate(date)}
                name="dob"
                onChange={handleChange}
                maxDate={moment().utc().subtract(13, 'years')._d}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {dob === false ? (
                <div className="error">Birthday is required</div>
              ) : null}
              {/* {validDob === false ? (
                <div className="error">Min 13 yrs required</div>
              ): null} */}
            </div>
            <div className="mb-2">
              <label className="form-label">Gender</label>
              <div className="radio-btn-group">
                <label>
                  <input
                    type="radio"
                    name="gender"
                    className=""
                    value="Woman"
                    checked={profileData?.gender === "Woman"}
                    onChange={onEditProfileChange("gender")}
                  />
                  <span className="btn btn-radio">Woman</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    className=""
                    value="Man"
                    checked={profileData?.gender === "Man"}
                    onChange={onEditProfileChange("gender")}
                  />
                  <span className="btn btn-radio">Man</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    className=""
                    value="Transgender"
                    checked={profileData?.gender === "Transgender"}
                    onChange={onEditProfileChange("gender")}
                  />
                  <span className="btn btn-radio">Transgender</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    className=""
                    value="Non-Binary"
                    checked={profileData?.gender === "Non-Binary"}
                    onChange={onEditProfileChange("gender")}
                  />
                  <span className="btn btn-radio">Non-Binary</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    className=""
                    value="Other"
                    checked={profileData?.gender === "Other"}
                    onChange={onEditProfileChange("gender")}
                  />
                  <span className="btn btn-radio">Other</span>
                </label>
              </div>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="state" className="form-label">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                value={profileData?.state}
                disabled={true}
                onChange={onEditProfileChange("state")}
              />
            </div> */}
            <div className="mb-3">
              <label htmlFor="cityName" className="form-label">
                Location
              </label>
              <input
                type="text"
                className="form-control"
                id="cityName"
                name="cityName"
                value={profileData?.cityName}
                disabled={true}
                onChange={onEditProfileChange("cityName")}
              />
            </div>
            <div className="text-center pt-3">
              <Button
                variant="primary"
                className="btn-large"
                disabled={disableButton}
                onClick={(event) => onSubmit(event)}
              >
                {loading === true ? (
                  <>
                    <span className="sr-only">Loading...</span>
                  </>
                ) : (
                  <>
                    Save Changes
                  </>
                )}
              </Button>
              <Button variant="transparent" className="btn-large" onClick={closeEditProfileModal}>
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditProfile;
