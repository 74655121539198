import React from "react";
import { Container } from "react-bootstrap";
import { logoImg } from "../../components/Images";
import { Link } from "react-router-dom";

const Security = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <header className="header-wrapper privacy-header">
        <Link to="/" className="navbar-brand">
          <img src={logoImg} className="nav-light-logo" alt="Artemizz" />
        </Link>
      </header>
      <Container className="privacy-content">
        <h1 className="title-small">Security</h1>
        <div>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos autem
            veritatis quod enim expedita officia laudantium quidem consequatur
            accusantium voluptates. Eius dolores accusamus illo nemo! Obcaecati
            quas beatae ut itaque repudiandae aliquam nisi labore quibusdam
            omnis incidunt quidem sunt dicta molestias in consequatur placeat
            rerum, harum doloribus voluptatum iusto ab velit dolores. Dolore rem
            recusandae sapiente alias eveniet, iusto quam beatae sed impedit
            temporibus laboriosam dicta delectus consequuntur fugiat natus quod
            doloribus fuga eius et esse necessitatibus nemo! Suscipit, facere
            rerum similique delectus ea eligendi, dolore deserunt fuga quae
            repudiandae sequi ipsa cupiditate omnis iusto natus tenetur
            accusamus culpa impedit? Lorem, ipsum dolor sit amet consectetur
            adipisicing elit. Incidunt cumque dolores cum nisi expedita quas rem
            ipsum harum? Cupiditate officiis modi magnam consequatur itaque
            saepe a laboriosam natus. Officiis sit iure modi ut rerum, non totam
            amet cumque animi illum. Officia, qui amet quisquam illo unde
            recusandae obcaecati assumenda necessitatibus vitae dolorum
            repellat, alias enim sed dignissimos cum magnam omnis! Eveniet
            dolores mollitia soluta, architecto non unde debitis ab obcaecati
            facilis saepe voluptates culpa iste cumque. Sequi enim voluptatum,
            repellendus nulla cum ea in ab at. Esse atque velit nulla,
            temporibus natus consequuntur ipsam et laboriosam vero doloribus
            ullam fuga ratione, eveniet magnam minus porro asperiores possimus
            nostrum beatae ex dignissimos odio. Dolore laboriosam soluta ipsam
            esse ea doloremque sint asperiores nisi, quasi ab culpa odio quod
            commodi placeat unde, iusto quisquam, aliquam tenetur explicabo
            voluptate natus excepturi veritatis alias officiis. Dignissimos,
            officia. Cum quam perferendis adipisci ex tenetur odit vero harum ab
            error quisquam consequatur neque reiciendis obcaecati similique,
            molestias nam modi cumque magnam a sequi. Eum doloribus veritatis,
            assumenda quibusdam praesentium quia autem adipisci eveniet magni
            error at labore consectetur atque nobis eos libero! Porro saepe
            placeat suscipit laborum doloribus facilis nisi accusamus vitae
            blanditiis? Distinctio dolorem modi, odit dicta nulla commodi non,
            unde, provident at facere enim a veritatis. Aliquam cumque qui sed
            illo suscipit molestias inventore vitae quos, iure perferendis
            quaerat unde nobis rem eos vero doloribus voluptatibus hic ratione
            consequatur architecto. Maxime cum qui veniam error reprehenderit
            voluptatem tenetur, nam excepturi tempore molestiae sunt optio animi
            praesentium non! Non voluptatum quo dolores eaque aspernatur vitae
            facilis soluta sint earum unde alias voluptates numquam, ab modi
            animi nam harum quam ea. Ducimus cum provident, quibusdam
            aspernatur, cumque ipsa voluptatibus quo quam officia alias
            voluptatem molestiae illum, vitae excepturi inventore earum quae.
            Laudantium nemo, ut dolor, dignissimos ipsa, eum quibusdam dolorem
            quasi numquam nesciunt sint neque. Vel obcaecati ipsum ab deserunt
            tempora placeat amet commodi neque, unde blanditiis debitis officia
            ex praesentium quae nemo nisi! Recusandae, officiis beatae quam eius
            autem sint quod soluta aspernatur ab aperiam voluptate assumenda
            laboriosam earum sunt! Nobis pariatur excepturi repellat alias error
            corrupti provident facilis adipisci commodi voluptate nostrum
            tempora fugit, exercitationem obcaecati eius dolore velit
            repellendus tenetur necessitatibus incidunt sit odio? Facilis
            doloribus dicta enim, magni suscipit reprehenderit repudiandae odit.
            Enim rerum aut sit possimus in eum. Ipsa neque hic nisi nesciunt quo
            deserunt explicabo voluptatum earum eos iusto dolorem distinctio
            deleniti pariatur ratione velit inventore, voluptates quos?
            Reprehenderit aliquam ullam doloribus ducimus quod sapiente
            consectetur laboriosam voluptatem cupiditate nesciunt beatae aperiam
            quo dolores, tempora reiciendis officiis ratione amet, fuga adipisci
            vel corporis vitae quae non. Accusamus, quibusdam! Aperiam alias
            doloremque voluptates odio sapiente non eveniet quis sunt, ipsam,
            officia nisi nulla delectus provident, ab totam exercitationem quae
            iusto! Odit eveniet adipisci, expedita aliquid minima asperiores
            odio reiciendis accusamus soluta dolorem temporibus, ducimus
            aspernatur quidem eligendi alias similique obcaecati suscipit illum,
            officiis quis nulla inventore? Illo, quos! Nam repellendus vitae
            laboriosam dignissimos tempore iure at vero excepturi, natus
            deserunt incidunt?
          </p>
        </div>
      </Container>
    </>
  );
};

export default Security;
