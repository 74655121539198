import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { changePassword } from "../../Services/api/endpoints";
import axiosInstance from "../../Services/middleware";
import { showToast } from '../../helper/swal';

const ChangePassword = ({ handleClick }) => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  // Change Password Form Initial Values
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }

  // Change Password Form Validation Schema
  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string().required('New password is required').matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      "Password must contain at least 8 characters with 1 upper case, 1 numeric and 1 special characters"
    ).when("currentPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().notOneOf(
        [Yup.ref("currentPassword")],
        "The Current password and New password is same."
      )
    }),
    confirmNewPassword: Yup.string().required('Confirm New password is required').when("newPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "The New password and confirmation password do not match."
      )
    })
  });

  /**
 * Reset Form
 * @returns 
 */
  const resetForm = () => {
    if (!formikRef.current) return;
    formikRef.current.resetForm();
  }

  /**
   * 
   * @param {*} values 
   */
  // Change password form Submit
  const submitHandler = async (values) => {
    setLoading(true)
    let body = {
      currentPassword: values?.currentPassword,
      newPassword: values?.newPassword
    };
    //console.log("payload1", body);
    try {
      const response = await axiosInstance.put(changePassword, body);
      let apiResponse = response.data
      if (apiResponse?.httpStatus === 200 && response.data?.success === true) {
        setLoading(false)
        showToast(apiResponse?.message, "success", false, 4500);
        handleClick(false)
        resetForm();
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        showToast(error.response.data?.message, "error", true);
      }
    }
  };

  function PasswordField({ name, placeholder }) {
    // password toggle eye
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
      setVisible(!isVisible);
    }

    return (
      <div className="position-relative">
        <Field type={isVisible ? 'text' : 'password'} name={name} className="form-control" placeholder={placeholder} autoComplete="off"></Field>
        <button type="button" className='eye-icons' onClick={() => toggle()}>
          <i className={isVisible ? "icon-eye" : "icon-eye-slash"}></i>
        </button>
      </div>
    )
  }

  return (
    <>
      <div className="profile-title mb-5">
        <h3 className="title-small text-primary mb-0">
          Security Settings
        </h3>
      </div>
      <div className="change-pass">
        <div className="mb-4">
          <h3 className="title-small">Change Password</h3>
          <p>Change the password that you use to sign-in</p>
        </div>
        <Formik initialValues={initialValues} innerRef={formikRef} validationSchema={changePasswordSchema}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ errors, touched }) => (
            <Form >
              <div className="mb-4 pass-field">
                <label htmlFor="current-pass" className="form-label">
                  Current Password
                </label>
                <PasswordField name="currentPassword" placeholder="Current Password" />
                <ErrorMessage component="div" className="error" name="currentPassword" />
              </div>
              <div className="mb-4 pass-field">
                <label htmlFor="new-pass" className="form-label">
                  New Password
                </label>
                <PasswordField name="newPassword" placeholder="New Password" />
                <ErrorMessage component="div" className="error" name="newPassword" />
              </div>
              <div className="mb-3 pass-field">
                <label htmlFor="confirm-pass" className="form-label">
                  Confirm New Password
                </label>
                <PasswordField name="confirmNewPassword" placeholder="Confirm New Password" />
                <ErrorMessage component="div" className="error" name="confirmNewPassword" />
              </div>
              <div className="submit-button-group w-100">
                <button type="submit" className="btn btn-primary btn-large round">
                  {loading === true ? (
                    <>
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                    <>
                      Save Changes
                    </>
                  )}
                </button>
                <button type="button" className="btn btn-transparent" onClick={() => { handleClick(false); resetForm() }}>Cancel</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default ChangePassword;