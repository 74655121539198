import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { logoImg } from "../../components/Images";
import { Link } from "react-router-dom";
import { cmsList } from "../../Services/api/endpoints";
import noToken from "../../Services/middleware/noToken";
import Loader from "../../components/loader/loader";
import { showToast } from "../../helper/swal";

const TermsConditions = () => {
  const [termsData, setTermsData] = useState([])
  const [loading, setLoading] = useState(false);

  async function cmsData() {
    try {
      setLoading(true)
      const response = await noToken(`${cmsList}`);
      const apiData = response.data.data[2];
      setTermsData(apiData);
      if (
        response.data?.httpStatus === 200 &&
        response.data?.success === true
      ) {
        setLoading(false);
      } else if (response.data?.success === false) {
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);

        showToast(error.response.data?.message, "error", true);

      }
    }
  }
  useEffect(() => {
    cmsData();
  }, [])
  window.scrollTo(0, 0);
  return (
    <>
      <header className="header-wrapper privacy-header">
        <Link to="/" className="navbar-brand">
          <img src={logoImg} className="nav-light-logo" alt="Artemizz" />
        </Link>
      </header>
      <Container className="privacy-content">
        {/* <h1 className="title-small">{termsData?.pageName}</h1> */}
        {loading === true ? (
          <>
            <Loader />
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: termsData?.pageHtml }}></div>
        )}
      </Container>
    </>
  );
};

export default TermsConditions;
