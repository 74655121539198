import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Thumbs, FreeMode, Navigation, Pagination } from "swiper";
import { StickyContainer, Sticky } from "react-sticky";
import FaqPage from "../../components/faq";
import Typewriter from "typewriter-effect";
// import AOS from "aos";
// import "aos/dist/aos.css";
import {
  problem_need_solution,
  guardian,
  personal,
  app_Store,
  insta,
  fb,
  partenership,
  support,
  artemizz_logo,
  device_timeline,
  banner_img,
  footer_img,
  dateicon,
  dataicon,
  rideicon,
  safety_checkin,
  tamper_proof,
  footprint,
  posts,
  location,
  chat,
  inform,
  connect,
  phone_call,
  map_dark,
  list,
  mask,
  sos_red,
  schedule,
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  pin1,
  pin2,
  pin3,
  pin4,
  pin5,
  pin6,
  step_1,
  step_2,
  step_3,
  device_map,
  privacy,
  sharing,
  device_friendshare,
  device_recorder,
  device_sos,
  center_logo,
  logoImg,
  logoBlue,
} from "../../components/Images";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const Homepage = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [windowScrollTop, setWindowScrollTop] = useState(0);
  // for header color change
  const header = useRef();
  const problemNeed = useRef();
  const timeline = useRef();
  // const pricing = useRef();
  const whyArtemizz = useRef();
  const faq = useRef();
  const problemNeedLink = useRef();
  const timelineLink = useRef();
  // const pricingLink = useRef();
  const whyArtemizzLink = useRef();
  const faqLink = useRef();
  const personalAccount = useRef();
  const headeraddclass = () => {
    if (!header.current) return;
    if (!problemNeed.current) return;
    if (!timeline.current) return;
    // if (!pricing.current) return;
    if (!whyArtemizz.current) return;
    if (!faq.current) return;
    if (!problemNeedLink.current) return;
    if (!timelineLink.current) return;
    // if (!pricingLink.current) return;
    if (!whyArtemizzLink.current) return;
    if (!faqLink.current) return;
    if (!personalAccount.current) return;
    let problemNeedTop = problemNeed.current.getBoundingClientRect().top;
    let problemNeedBottom = problemNeed.current.getBoundingClientRect().bottom;
    let timelineTop = timeline.current.getBoundingClientRect().top;
    let timelineBottom = timeline.current.getBoundingClientRect().bottom;
    // let pricingTop = pricing.current.getBoundingClientRect().top;
    // let pricingBottom = pricing.current.getBoundingClientRect().bottom;
    let whyArtemizzTop = whyArtemizz.current.getBoundingClientRect().top;
    let whyArtemizzBottom = whyArtemizz.current.getBoundingClientRect().bottom;
    let faqTop = faq.current.getBoundingClientRect().top;
    let faqbottom = faq.current.getBoundingClientRect().bottom;
    let personalAccountTop = personalAccount.current.getBoundingClientRect().top;
    let personalAccountBottom = personalAccount.current.getBoundingClientRect().bottom;

    let headerBottom = header.current.getBoundingClientRect().bottom;
    let section1 = headerBottom < problemNeedTop || headerBottom > problemNeedBottom;
    let section2 = headerBottom < timelineTop || headerBottom > timelineBottom;
    // let section3 = headerBottom < pricingTop || headerBottom > pricingBottom;
    let section4 = headerBottom < whyArtemizzTop || headerBottom > whyArtemizzBottom;
    let section5 = headerBottom < faqTop || headerBottom > faqbottom;
    let section6 = headerBottom < personalAccountTop || headerBottom > personalAccountBottom;
    // if (section1 && section3 && section4) {
    if (section1 && section4) {
      header.current.classList.remove("header-dark-color");
    } else {
      header.current.classList.add("header-dark-color");
    }
    if (section2 && section5 && section6) {
      header.current.classList.remove("blue-bg-color");
    } else {
      header.current.classList.add("blue-bg-color");
    }
    // switch (!section1 && !section2 && !section3 && !section4 && !section5) {
    switch (!section1 && !section2 && !section4 && !section5) {
      case section1:
        problemNeedLink.current.classList.add("dark-border");
        timelineLink.current.classList.remove("white-border");
        // pricingLink.current.classList.remove("dark-border");
        whyArtemizzLink.current.classList.remove("dark-border");
        faqLink.current.classList.remove("white-border");
        break;
      case section2:
        timelineLink.current.classList.add("white-border");
        problemNeedLink.current.classList.remove("dark-border");
        // pricingLink.current.classList.remove("dark-border");
        whyArtemizzLink.current.classList.remove("dark-border");
        faqLink.current.classList.remove("white-border");

        break;
      // case section3:
      //   pricingLink.current.classList.add("dark-border");
      //   problemNeedLink.current.classList.remove("dark-border");
      //   timelineLink.current.classList.remove("white-border");
      //   whyArtemizzLink.current.classList.remove("dark-border");
      //   faqLink.current.classList.remove("white-border");
      //   break;
      case section4:
        whyArtemizzLink.current.classList.add("dark-border");
        problemNeedLink.current.classList.remove("dark-border");
        timelineLink.current.classList.remove("white-border");
        // pricingLink.current.classList.remove("dark-border");
        faqLink.current.classList.remove("white-border");
        break;
      case section5:
        faqLink.current.classList.add("white-border");
        problemNeedLink.current.classList.remove("dark-border");
        timelineLink.current.classList.remove("white-border");
        // pricingLink.current.classList.remove("dark-border");
        whyArtemizzLink.current.classList.remove("dark-border");
        break;
      default:
        problemNeedLink.current.classList.remove("dark-border");
        timelineLink.current.classList.remove("white-border");
        // pricingLink.current.classList.remove("dark-border");
        whyArtemizzLink.current.classList.remove("dark-border");
        faqLink.current.classList.remove("white-border");
    }
  };
  const goToLanding = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
    const isMobile = window.matchMedia("only screen and (max-width: 768px)");
    if (!isMobile.matches) {
      window.addEventListener("scroll", () => {
        setWindowScrollTop(window.scrollY);
      });
    }
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY >= 250);
    });
    headeraddclass();
  }, [windowScrollTop]);
  // for smooth scroll
  function scrollViewer(link) {
    let e = document.getElementById(link);
    e.scrollIntoView({ behavior: "auto" });
  }
  // for scroll top on page refresh
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);
  return (
    <>
      <div className="homepage-wrapper">
        <header
          className={
            scroll
              ? "header-wrapper homepage-header is-sticky"
              : "header-wrapper homepage-header"
          }
          id="homepageheader"
          ref={header}
        >
          <Navbar collapseOnSelect expand="md" bg="transparent">
            <Container fluid className="xs-ps-0">
              <div className="top-menu">
                <Link to="/" className="navbar-brand" onClick={goToLanding}>
                  <img src={logoImg} className="nav-light-logo" alt="Artemizz" />
                  <img src={logoBlue} className="nav-blue-logo" alt="Artemizz" />
                </Link>
                <div
                  className={`${!showMenu ? "burger-menu" : "burger-menu menu"
                    }`}
                  role="menu"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <div className="burger"></div>
                </div>
              </div>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className={`${showMenu ? "show" : ""}`}
              >
                <ul className="ms-auto right-menu navbar-nav justify-content-end">
                  <li>
                    <span
                      ref={problemNeedLink}
                      onClick={() => {
                        scrollViewer("problem-need");
                        setShowMenu(false);
                      }}
                      className="nav-link"
                    >
                      About
                    </span>
                  </li>
                  <li>
                    <span
                      ref={timelineLink}
                      onClick={() => {
                        scrollViewer("timeline");
                        setShowMenu(false);
                      }}
                      className="nav-link"
                    >
                      Features
                    </span>
                  </li>
                  {/* <li>
                    <span
                      ref={pricingLink}
                      onClick={() => {
                        scrollViewer("pricing");
                        setShowMenu(false);
                      }}
                      className="nav-link"
                    >
                      Pricing
                    </span>
                  </li> */}
                  <li>
                    <span
                      ref={whyArtemizzLink}
                      onClick={() => {
                        scrollViewer("why-artemizz");
                        setShowMenu(false);
                      }}
                      className="nav-link"
                    >
                      Why
                    </span>
                  </li>
                  <li>
                    <span
                      ref={faqLink}
                      onClick={() => {
                        scrollViewer("faq");
                        setShowMenu(false);
                      }}
                      className="nav-link"
                    >
                      Help
                    </span>
                  </li>
                  <li>
                    <Link
                      to="/login"
                      className="nav-link btn btn-primary round signin-btn"
                    >
                      Sign In
                    </Link>
                  </li>
                </ul>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <section className="banner-section">
          <div className="banner-container">
            <Row className="banner-inner">
              <Col lg={6} className="mb-md-0 mb-3">
                <h1
                  className="heading"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <Typewriter
                    options={{
                      strings: ["Proactive Personal & Family Safety"],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: "natural",
                      delay: 50,
                    }}
                  />
                </h1>
                <p
                  className="description"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  Capture and share the details that matter all from a single
                  app. <br className="d-none d-sm-block" />
                  Artemizz offers streamlined personal witness technology that
                  has <br className="d-none d-xl-block" />
                  your back and connects you to your world
                </p>
                <a
                  href="https://apps.apple.com/us/app/artemizz-advanced-safety/id1642861256"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-store-img d-none d-md-block"
                  data-aos="fade-right"
                  data-aos-delay="400"
                  data-aos-offset="10"
                >
                  <img src={app_Store} alt="app-store" />
                </a>
                <p
                  className="trial d-none d-md-block"
                  data-aos="zoom-in"
                  data-aos-delay="500"
                  data-aos-offset="10"
                >
                  Sign up today for FREE!
                </p>
              </Col>
              <Col lg={6} className="mb-md-0 mb-4">
                <div
                  className="image-box"
                  data-aos="fade-left"
                  data-aos-offset="10"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={banner_img} alt="banner" />
                </div>
              </Col>
            </Row>
            <Row
              className="d-md-none d-block"
              data-aos="fade-right"
              data-aos-delay="10"
              data-aos-offset="10"
            >
              <Col>
                <a href="https://apps.apple.com/us/app/artemizz-advanced-safety/id1642861256"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-store-img">
                  <img src={app_Store} alt="app-store" />
                </a>
                <p className="trial mb-1">Sign up today for FREE!</p>
              </Col>
            </Row>
          </div>
        </section>
        <section
          className="problem-need-solution"
          id="problem-need"
          ref={problemNeed}
        >
          <Container>
            <Row className="problem-inner-row align-items-center">
              <Col lg={6}>
                <div
                  className="image-box"
                  data-aos="fade-right"
                  data-aos-easing="ease-in-sine"
                  data-aos-offset="0"
                >
                  <img
                    src={problem_need_solution}
                    alt="problem-need-solution"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="" data-aos="fade-left" data-aos-offset="10">
                  <h2 className="heading">
                    Problems that need better <span>Solutions…</span>
                  </h2>
                  <div className="para">
                    <p>
                      What would you do if something bad happened to you and you
                      didn't have a way to <span>prove it</span> or{" "}
                      <span>get help?</span>
                    </p>
                    <p>
                      {" "}
                      You'd probably need to use 3 or 4 different apps to get
                      help, share the details with your network and verify your
                      activities later if necessary.
                    </p>
                    <p className="subheading">
                      {" "}
                      Artemizz technology combines it into a single app
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="timeline-section" id="timeline" ref={timeline}>
          <Container>
            <div className="timeline-inner">
              <h2
                className="heading"
                data-aos="fade-right"
                data-aos-offset="10"
              >
                The Artemizz interactive Timeline is the answer
              </h2>
              <Swiper
                autoplay={{
                  delay: 20000,
                  disableOnInteraction: false,
                  waitForTransition: true,
                }}
                spaceBetween={10}
                navigation={false}
                pagination={{ clickable: true }}
                loop={true}
                speed={200}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[Autoplay, FreeMode, Navigation, Pagination, Thumbs]}
                className="mySwiper2"
              >
                <SwiperSlide>
                  <p className="subheading">
                    It's more comprehensive, provides greater functionality, and
                    better control for users
                  </p>
                  <Row>
                    <Col lg={6}>
                      <div className="timeline-left">
                        <div className="timeline-left-div">
                          <div className="timeline-box">
                            <div className="timeline-box-content">
                              <div className="icons">
                                <i className="icon-timeline_active"></i>
                              </div>
                              <span>Timeline</span>
                            </div>
                            <p className="status">Patent Pending</p>
                          </div>
                          <p className="left-para">
                            Going on a date? Catching a ride somewhere? Post it
                            to your Timeline to create a record and share it
                            with your network
                          </p>
                        </div>
                        <div className="timeline-bottom-text">
                          <h4>The good kind of footprint</h4>
                          <p>
                            Artemizz can track all kinds of activities because
                            we understand everyone's different
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="timeline-mobile-box">
                        <div
                          className="image-box"
                          data-aos="fade-left"
                          data-aos-easing="ease-in-sine"
                          data-aos-offset="0"
                        >
                          <img src={device_timeline} alt="Timeline" />
                        </div>
                        <div
                          className="timeline-card-popup-1"
                          data-aos="fade-up"
                          data-aos-delay="10"
                          data-aos-offset="5"
                        >
                          <img src={tamper_proof} alt="Tamper proof" />
                          <span>Tamper-proof design</span>
                        </div>
                        <div
                          className="timeline-card-popup-2"
                          data-aos="fade-up"
                          data-aos-delay="20"
                          data-aos-offset="5"
                        >
                          <img src={dateicon} alt="date" />
                          <span>Track dates from all dating apps</span>
                        </div>
                        <div
                          className="timeline-card-popup-3"
                          data-aos="fade-up"
                          data-aos-delay="30"
                          data-aos-offset="5"
                        >
                          <img src={safety_checkin} alt="safety checkin" />
                          <span>Receive daily Safety Check-Ins</span>
                        </div>

                        <div
                          className="timeline-card-popup-4"
                          data-aos="fade-up"
                          data-aos-delay="40"
                          data-aos-offset="5"
                        >
                          <img src={rideicon} alt="ride" />
                          <span>
                            {/* Track all ride types e.g., train, bus, subway, car */}
                            Keep track when you're on the move
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-5"
                          data-aos="fade-up"
                          data-aos-delay="50"
                          data-aos-offset="5"
                        >
                          <img src={dataicon} alt="data" />
                          {/* <span>All your data easily accessible</span> */}
                          <span>Complete control over your data</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
                <SwiperSlide>
                  <p className="subheading">
                    It's a superior map that automates your safety habits
                  </p>
                  <Row>
                    <Col lg={6}>
                      <div className="timeline-left">
                        <div className="timeline-left-div">
                          <div className="timeline-box">
                            <div className="timeline-box-content">
                              <div className="icons">
                                <i className="icon-map"></i>
                              </div>
                              <span>Map</span>
                            </div>
                            <p className="status">Patent Pending</p>
                          </div>
                          <p className="left-para">
                            The Artemizz Map visually organizes your Timeline.
                            Set up Frequent Location alerts for the places you
                            visit most
                          </p>
                        </div>
                        <div className="timeline-bottom-text">
                          <h4>Checking-in without the hassle</h4>
                          <p>
                            Frequent Locations automatically let your loved ones
                            know when you get where you're going
                            {/* Frequent Locations automatically let your loved ones
                            know when you get home safely */}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="timeline-mobile-box">
                        <div
                          className="image-box"
                          data-aos="fade-left"
                          data-aos-easing="ease-in-sine"
                          data-aos-offset="0"
                        >
                          <img src={device_map} alt="Map" />
                        </div>
                        <div
                          className="timeline-card-popup-6"
                          data-aos="fade-up"
                          data-aos-delay="10"
                          data-aos-offset="5"
                        >
                          <img src={location} alt="location" />
                          <span>
                            {/* Set up Frequent Locations for the places you visit
                            most */}
                            Automatically track when you come and go
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-7"
                          data-aos="fade-up"
                          data-aos-delay="20"
                          data-aos-offset="5"
                        >
                          <img
                            src={posts}
                            alt="posts"
                            className="post-triangle"
                          />
                          <span>
                            {/* Intuitive UI displays Posts by location and Post
                            type */}
                            Intuitively displayed information
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-8"
                          data-aos="fade-up"
                          data-aos-delay="30"
                          data-aos-offset="5"
                        >
                          <img
                            src={footprint}
                            alt="footprint"
                            className="footprint"
                          />
                          <span>
                            {/* Use the Map to retrace your footprints from previous
                            days */}
                            Retrace your footprints with the map
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
                <SwiperSlide>
                  <p className="subheading">
                    It's a better way to share important details
                  </p>
                  <Row>
                    <Col lg={6}>
                      <div className="timeline-left">
                        <div className="timeline-left-div">
                          <div className="timeline-box">
                            <div className="timeline-box-content">
                              <div className="icons">
                                <i className="icon-friendshare"></i>
                              </div>
                              <span>FriendShare</span>
                            </div>
                            <p className="status">Patent Pending</p>
                          </div>
                          <p className="left-para">
                            FriendShare lets you connect with friends and easily
                            share your Timeline with the people you trust. You
                            control what you share
                          </p>
                        </div>
                        <div className="timeline-bottom-text">
                          <h4 className="timeline-sub-title">
                            Staying in touch simplified
                          </h4>
                          <p>
                            Whenever you post something new to your Timeline,
                            people who have permission will automatically get
                            the update
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="timeline-mobile-box">
                        <div
                          className="image-box"
                          data-aos="fade-left"
                          data-aos-easing="ease-in-sine"
                          data-aos-offset="0"
                        >
                          <img src={device_friendshare} alt="Share" />
                        </div>
                        <div
                          className="timeline-card-popup-9"
                          data-aos="fade-up"
                          data-aos-delay="10"
                          data-aos-offset="5"
                        >
                          <img src={connect} alt="connect" />
                          <span>
                            {/* Connect with friends and family to maximize your
                            protection */}
                            Connect with up to 9 people
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-10"
                          data-aos="fade-up"
                          data-aos-delay="20"
                          data-aos-offset="5"
                        >
                          <img src={safety_checkin} alt="safety checkin" />
                          <span>
                            {/* Help keep your friends and family safe by
                            “checking-in” on their activity */}
                            Check-in on your friends and family
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-11"
                          data-aos="fade-up"
                          data-aos-delay="30"
                          data-aos-offset="5"
                        >
                          <img src={inform} alt="inform" />
                          <span>
                            {/* Information clearly displayed lets you get to the
                            point quick */}
                            Simple design lets you get to the point quick
                          </span>
                        </div>

                        <div
                          className="timeline-card-popup-12"
                          data-aos="fade-up"
                          data-aos-delay="40"
                          data-aos-offset="5"
                        >
                          <img src={chat} alt="chat" />
                          <span>
                            {/* Use our in-app chat to privately talk to your
                            network */}
                            Privately chat with your network
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
                <SwiperSlide>
                  <p className="subheading">
                    It's an extra set of ears that captures evidence
                  </p>
                  <Row>
                    <Col lg={6}>
                      <div className="timeline-left">
                        <div className="timeline-left-div">
                          <div className="timeline-box">
                            <div className="timeline-box-content">
                              <div className="icons">
                                <i className="icon-recorder"></i>
                              </div>
                              <span>Record</span>
                            </div>
                            <p className="status">Patent Pending</p>
                          </div>
                          <p className="left-para">
                            Artemizz is designed to help you discreetly gather
                            crucial evidence and safeguard it for later
                          </p>
                        </div>
                        <div className="timeline-bottom-text">
                          <h4 className="timeline-sub-title">
                            Proactively schedule recordings
                          </h4>
                          <p>
                            Artemizz can schedule a recording ahead of time
                            which means one less thing for you to worry about
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="timeline-mobile-box">
                        <div
                          className="image-box"
                          data-aos="fade-left"
                          data-aos-easing="ease-in-sine"
                          data-aos-offset="0"
                        >
                          <img src={device_recorder} alt="Recorder" />
                        </div>

                        <div
                          className="timeline-card-popup-13"
                          data-aos="fade-up"
                          data-aos-delay="10"
                          data-aos-offset="5"
                        >
                          <img src={location} alt="map" />
                          <span>
                            {/* Artemizz keeps track of the location your recordings
                            were made */}
                            Track recordings by location made
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-14"
                          data-aos="fade-up"
                          data-aos-delay="20"
                          data-aos-offset="5"
                        >
                          <img src={list} alt="list" />
                          <span>
                            {/* Easily manage recordings directly from your device */}
                            Manage recordings straight from your device
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-15"
                          data-aos="fade-up"
                          data-aos-delay="30"
                          data-aos-offset="5"
                        >
                          <img src={mask} alt="mask" />
                          <span>
                            {/* Use the 'mask screen' feature to give yourself
                            privacy while recording */}
                            Mask your screen for privacy
                          </span>
                        </div>

                        <div
                          className="timeline-card-popup-16"
                          data-aos="fade-up"
                          data-aos-delay="40"
                          data-aos-offset="5"
                        >
                          <img src={schedule} alt="schedule" />
                          <span>
                            {/* Schedule a recording ahead of time using the Record
                            Later feature */}
                            Schedule recordings ahead of time
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
                <SwiperSlide>
                  <p className="subheading">
                    It's a way to get help from people you know and trust
                  </p>
                  <Row>
                    <Col lg={6}>
                      <div className="timeline-left">
                        <div className="timeline-left-div">
                          <div className="timeline-box">
                            <div className="timeline-box-content">
                              <div className="icons">
                                <i className="icon-sos"></i>
                              </div>
                              <span>SOS</span>
                            </div>
                            <p className="status">Patent Pending</p>
                          </div>
                          <p className="left-para">
                            Sense that you're in a dangerous situation or just
                            feeling uncomfortable? Get help quickly with the
                            push of a button
                          </p>
                        </div>
                        <div className="timeline-bottom-text">
                          <h4 className="timeline-sub-title">
                            Get help from your people
                          </h4>
                          <p>
                            Industry-leading SOS technology that gets you out of
                            trouble by leveraging the people in your life you
                            know and trust
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="timeline-mobile-box">
                        <div
                          className="image-box"
                          data-aos="fade-left"
                          data-aos-easing="ease-in-sine"
                          data-aos-offset="0"
                        >
                          <img src={device_sos} alt="SOS" />
                        </div>
                        <div
                          className="timeline-card-popup-17"
                          data-aos="fade-up"
                          data-aos-delay="10"
                          data-aos-offset="5"
                        >
                          <img src={sos_red} alt="SOS" />
                          <span>
                            Andrea activated the
                            <strong className="color-orange"> SOS</strong>
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-18"
                          data-aos="fade-up"
                          data-aos-delay="20"
                          data-aos-offset="5"
                        >
                          <img src={phone_call} alt="phone call" />
                          <span>
                            {/* In-app call button to quickly get in touch during an
                            emergency */}
                            Organized calling directly from the app
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-19"
                          data-aos="fade-up"
                          data-aos-delay="30"
                          data-aos-offset="5"
                        >
                          <img src={map_dark} alt="map dark" />
                          <span>
                            {/* Artemizz gives your network the quickest route to
                            your location */}
                            Find the quickest route to provide help
                          </span>
                        </div>

                        <div
                          className="timeline-card-popup-20"
                          data-aos="fade-up"
                          data-aos-delay="40"
                          data-aos-offset="5"
                        >
                          <img src={location} alt="map" />
                          <span>
                            {/* Real-time location keeps your network updated on
                            your movements */}
                            Access real-time location during an SOS
                          </span>
                        </div>
                        <div
                          className="timeline-card-popup-21"
                          data-aos="fade-up"
                          data-aos-delay="50"
                          data-aos-offset="5"
                        >
                          <img src={chat} alt="chat" />
                          <span>
                            {/* Proprietary SOS group chat lets your network work
                            together to get you help */}
                            Proprietary SOS group chat
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
              </Swiper>
              <div className="thumb-swiper-btn">
                <p className="other-features">Other Features</p>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={3}
                  slidesPerView={5}
                  watchSlidesProgress={true}
                  touchRatio={4}
                  threshold={20}
                  modules={[Navigation, Thumbs]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="timeline-features-box">
                      <div className="icons">
                        {" "}
                        <i className="icon-timeline_active"></i>
                      </div>
                      <p>Timeline</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="timeline-features-box">
                      <div className="icons">
                        <i className="icon-map"></i>
                      </div>
                      <p>Map</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="timeline-features-box">
                      <div className="icons">
                        <i className="icon-friendshare"></i>
                      </div>
                      <p>FriendShare</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="timeline-features-box">
                      <div className="icons">
                        <i className="icon-recorder"></i>
                      </div>
                      <p>Record</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="timeline-features-box">
                      <div className="icons">
                        <i className="icon-sos"></i>
                      </div>
                      <p>SOS</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </Container>
        </section>
        {/* <section className="pricing" id="pricing" ref={pricing}>
          <Container>
            <StickyContainer>
              <div className="pricing-row">
                <div className="d-block d-sm-none">
                  <Sticky topOffset={-52} bottomOffset={62}>
                    {({ style, isSticky }) => (
                      <h2
                        style={{
                          ...style,
                          marginTop: isSticky ? "52px" : "0px",
                        }}
                        className="heading sticky-heading-top bg-cover"
                      >
                        Pricing
                      </h2>
                    )}
                  </Sticky>
                </div>
                <h2 className="heading sticky-heading-top bg-cover unsticky-desk d-none d-sm-block">
                  Pricing
                </h2>
                <Row className="pricing-box gx-0">
                  <Col md={6} className="bg-pricing-box">
                    <div
                      className="personal-account"
                      data-aos="fade-left"
                      data-aos-offset="10"
                    >
                      <div className="profile">
                        <img src={personal} alt="personal account" />
                        <h3>Personal Account</h3>
                      </div>
                      <p className="account-status">
                        <span>$2.99/month</span> or <span>$30/year</span> (save
                        over 16%!)*
                      </p>
                      <p>
                        A Personal Account provides complete access to all of
                        the great features Artemizz has to offer. Post important
                        details to your Timeline and share them with your
                        network in a single, streamlined app.
                      </p>
                      <div className="d-flex justify-content-between">
                        <ul className="account-features me-2">
                          <li>Safety Timeline (track rides, dates, location & media)</li>
                          <li>Connect and share with up to 9 friends</li>
                          <li>Discreet recording technology</li>
                          <li>Proprietary SOS Alert and SOS Group Chat</li>
                          <li>Includes 2 Free Guardian Accounts</li>
                          <li>5 Frequent Location Alerts</li>
                          <li>Private In-app Chat</li>
                          <li>Daily Safety Check-Ins</li>
                        </ul>
                      </div>
                      <div className="account-bottom">
                        <a href="https://apps.apple.com/us/app/artemizz-advanced-safety/id1642861256"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary">
                          Start 30 Day Free Trial
                        </a>
                        <div className="app-available">
                          Avaliable on iOS, coming soon to Android
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="bg-pricing-box">
                    <div
                      className="guardian-account"
                      data-aos="fade-right"
                      data-aos-offset="10"
                    >
                      <div className="profile">
                        <img src={guardian} alt="guardian account" />
                        <h3>Guardian Account</h3>
                      </div>
                      <p className="account-status">
                        <span>Free Membership**</span>
                      </p>
                      <p>
                        Guardian Account users can take an active role in their
                        family and friends' lives serving as their safety
                        network. Use a Guardian Account to view important details that they've shared with you on their Timeline.
                        You'll also be the first notified when they trigger an SOS Alert!
                      </p>
                      <ul className="account-features">
                        <li>Monitor your loved one's Safety Timeline</li>
                        <li>Get notified when your loved ones are taking rides, going on dates and when they make it home safely</li>
                        <li>Receive alerts when your loved ones trigger an SOS</li>
                        <li>Access real-time location during SOS Alerts and utilize our proprietary SOS Group Chat</li>
                        <li>Privately communicate through our in-app chat</li>
                      </ul>
                      <div className="account-bottom">
                        <a href="https://apps.apple.com/us/app/artemizz-advanced-safety/id1642861256"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary">
                          Get Started
                        </a>
                        <div className="app-available">
                          Avaliable on iOS, coming soon to Android
                        </div>
                      </div>
                      <p className="text-center note d-md-none mb-1">
                        *$2.99/month or $30/year for the 2GB Plan and $9.99/month or $100/year for the 10GB Plan.
                      </p>
                      <p className="text-center note d-md-none">
                        **Each Personal Account comes with two free Guardian
                        Accounts. Guardian Account sign-up requires an existing
                        or newly activated Personal Account.
                      </p>
                    </div>
                  </Col>
                  <p className="text-center note d-none d-md-block mb-1">
                    *$2.99/month or $30/year for the 2GB Plan and $9.99/month or $100/year for the 10GB Plan.
                  </p>
                  <p className="text-center note d-none d-md-block">
                    **Each Personal Account comes with two free Guardian
                    Accounts. Guardian Account sign-up requires an existing or newly activated Personal Account.
                  </p>
                </Row>
              </div>
            </StickyContainer>
          </Container>
        </section> */}
        <section className="personal-account-section" ref={personalAccount}>
          <div className="personal-inner">
            <h2 className="heading" data-aos="fade-left" data-aos-offset="10">
              How the Personal Account Works
            </h2>
            <div className="personal-account-inner">
              <div className="road-animation"></div>
              <Container>
                <div className="personal-account-card-row">
                  <div className="personal-account-card">
                    <div
                      className="personal-account-card-inner"
                      data-aos="fade-down"
                      data-aos-delay="50"
                      data-aos-offset="10"
                    >
                      <div className="pa-top">
                        <span className="d-none d-md-block">#1</span>
                        <img src={card1} alt="card" />
                      </div>
                      <p>
                        Going on a date? Catching a ride somewhere? Or are you
                        just feeling unsafe?
                      </p>
                    </div>
                    <div
                      className="pin-wrap"
                      data-aos="zoom-in"
                      data-aos-delay="40"
                      data-aos-offset="10"
                    >
                      <img src={pin1} className="pin-image" alt="card" />
                    </div>
                  </div>
                  <div className="personal-account-card">
                    <div
                      className="personal-account-card-inner"
                      data-aos="fade-down"
                      data-aos-delay="60"
                      data-aos-offset="10"
                    >
                      <div className="pa-top">
                        <span className="d-none d-md-block">#2</span>
                        <img src={card2} alt="card" />
                      </div>
                      <p>
                        Post an update to your Timeline to create a record of
                        what's going on
                      </p>
                    </div>
                    <div
                      className="pin-wrap"
                      data-aos="zoom-in"
                      data-aos-delay="50"
                      data-aos-offset="10"
                    >
                      <img src={pin2} className="pin-image" alt="card" />
                    </div>
                  </div>
                  <div className="personal-account-card">
                    <div
                      className="personal-account-card-inner"
                      data-aos="fade-down"
                      data-aos-delay="70"
                      data-aos-offset="10"
                    >
                      <div className="pa-top">
                        <span className="d-none d-md-block">#3</span>
                        <img src={card3} alt="card" />
                      </div>
                      <p>
                        The Artemizz tamper-proof design prevents intruders from
                        manipulating your data
                      </p>
                    </div>
                    <div
                      className="pin-wrap"
                      data-aos="zoom-in"
                      data-aos-delay="60"
                      data-aos-offset="10"
                    >
                      <img src={pin4} className="pin-image" alt="card" />
                    </div>
                  </div>
                  <div className="personal-account-card">
                    <div
                      className="personal-account-card-inner"
                      data-aos="fade-down"
                      data-aos-delay="80"
                      data-aos-offset="10"
                    >
                      <div className="pa-top">
                        <span className="d-none d-md-block">#4</span>
                        <img src={card4} alt="card" />
                      </div>
                      <p>
                        Give friends permission to view your Timeline and
                        leverage the power of your network. Share data on your
                        terms
                      </p>
                    </div>
                    <div
                      className="pin-wrap"
                      data-aos="zoom-in"
                      data-aos-delay="70"
                      data-aos-offset="10"
                    >
                      <img src={pin3} className="pin-image" alt="card" />
                    </div>
                  </div>
                  <div className="personal-account-card">
                    <div
                      className="personal-account-card-inner"
                      data-aos="fade-down"
                      data-aos-delay="90"
                      data-aos-offset="10"
                    >
                      <div className="pa-top">
                        <span className="d-none d-md-block">#5</span>
                        <img src={card5} alt="card" />
                      </div>
                      <p>
                        Get out of danger with our industry-leading SOS
                        technology that ensures your people can help quickly
                      </p>
                    </div>
                    <div
                      className="pin-wrap"
                      data-aos="zoom-in"
                      data-aos-delay="80"
                      data-aos-offset="10"
                    >
                      <img src={pin6} className="pin-image" alt="card" />
                    </div>
                  </div>
                  <div className="personal-account-card">
                    <div
                      className="personal-account-card-inner"
                      data-aos="fade-down"
                      data-aos-delay="100"
                      data-aos-offset="10"
                    >
                      <div className="pa-top">
                        <span className="d-none d-md-block">#6</span>
                        <img src={card6} alt="card" />
                      </div>
                      <p>
                        {/* Even if your phone gets stolen, all of your data is
                        safely stored on our secure servers */}
                        Even if your phone gets stolen, your data is safely stored on our secure servers
                      </p>
                    </div>
                    <div
                      className="pin-wrap"
                      data-aos="zoom-in"
                      data-aos-delay="90"
                      data-aos-offset="10"
                    >
                      <img src={pin5} className="pin-image" alt="card" />
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </section>
        <section className="guardian-account-section">
          <Container>
            <StickyContainer>
              <div className="guardian-card-row">
                <div className="d-block d-sm-none">
                  <Sticky topOffset={-52} bottomOffset={62}>
                    {({ style, isSticky }) => (
                      <h2
                        style={{
                          ...style,
                          marginTop: isSticky ? "52px" : "0px",
                        }}
                        className="heading sticky-heading-top bg-cover"
                      >
                        How the Guardian Account Works
                      </h2>
                    )}
                  </Sticky>
                </div>
                <h2 className="heading sticky-heading-top bg-cover d-none d-sm-block">
                  How the Guardian Account Works
                </h2>
                <div className="card-wrapper">
                  <div
                    className="card"
                    data-aos="fade-right"
                    data-aos-delay="10"
                    data-aos-offset="10"
                  >
                    <div className="card-top">
                      <label>
                        Create a Personal Account and receive 2 free Guardian
                        Accounts
                      </label>
                      <p className="thought">
                        It's peace of mind that you're not alone
                      </p>
                    </div>
                    <div className="card-bottom">
                      <img src={step_1} alt="card" />
                    </div>
                    <span className="card-top-number">#1</span>
                  </div>
                  <div
                    className="card image-top"
                    data-aos="fade-up"
                    data-aos-delay="20"
                    data-aos-offset="10"
                  >
                    <div className="card-top">
                      <img src={step_2} alt="card" />
                    </div>
                    <div className="card-bottom">
                      <label>
                        Invite your family to join as free Guardian Account
                        users and set their viewing privileges
                      </label>
                      <p className="thought">
                        It's peace of mind for your family and a better way to
                        stay in touch
                      </p>
                    </div>
                    <span className="card-top-number">#2</span>
                  </div>
                  <div
                    className="card"
                    data-aos="fade-left"
                    data-aos-delay="30"
                    data-aos-offset="10"
                  >
                    <div className="card-top">
                      <label>
                        {/* Your family stays connected, and can quickly and easily
                        lend a helping hand during emergencies */}
                        Your family stays updated on your activities and can quickly lend a helping hand during emergencies
                      </label>
                      <p className="thought">
                        It's peace of mind for your entire network
                      </p>
                    </div>
                    <div className="card-bottom">
                      <img src={step_3} alt="card" />
                    </div>
                    <span className="card-top-number">#3</span>
                  </div>
                </div>
              </div>
            </StickyContainer>
          </Container>
        </section>
        <section
          className="why-artemizz-section"
          id="why-artemizz"
          ref={whyArtemizz}
        >
          <Container>
            <div className="why-artemizz">
              <h2 className="heading">Why Artemizz is Better</h2>
              <p className="subheading">
                {/* Artemizz provides greater functionality, is more comprehensive,
                and gives users more control over their data */}
                It's more comprehensive, provides greater functionality, and better control for users
              </p>
              <div
                className="title-text"
                data-aos="zoom-out"
                data-aos-delay="50"
                data-aos-offset="10"
              >
                Privacy First. Sharing Second. Safety Always.
              </div>
              <div className="privacy-sharing">
                <div
                  className="privacy"
                  data-aos="fade-right"
                  data-aos-delay="10"
                  data-aos-offset="10"
                >
                  <div className="privacy-inner">
                    <img src={privacy} alt="Privacy" />
                    <h5>Privacy</h5>
                    <p>
                      Data privacy is our top concern. Because your data is
                      treated the way we want our data treated
                    </p>
                    <p>
                    It's simply a better form of safety
                    </p>
                  </div>
                </div>
                <div className="center-logo">
                  <img
                    src={center_logo}
                    alt="Artemizz"
                    data-aos="zoom-out"
                    data-aos-delay="20"
                    data-aos-offset="10"
                  />
                </div>
                <div
                  className="sharing"
                  data-aos="fade-left"
                  data-aos-delay="10"
                  data-aos-offset="10"
                >
                  <div className="sharing-inner">
                    <img src={sharing} alt="Share" />
                    <h5>Easy, one-step sharing</h5>
                    <p>
                      'Checking-in' or 'touching-base' has never been easier
                    </p>
                    <p>
                      The best part is, data sharing is completely up to you
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="faq" id="faq" ref={faq}>
          <FaqPage />
        </section>
        <footer className="site-footer">
          <Container>
            <Row className="site-footer-top">
              <Col
                lg={5}
                md={8}
                data-aos="fade-right"
                data-aos-offset="10"
                data-aos-easing="ease-in-sine"
              >
                <div className="image-box">
                  <img src={footer_img} alt="Background" />
                </div>
              </Col>
              <Col
                lg={5}
                data-aos="fade-left"
                data-aos-offset="10"
                data-aos-easing="ease-in-sine"
              >
                <h4 className="heading">
                  Available on the <br /> App Store
                </h4>
                <a className="app-store-img" href="https://apps.apple.com/us/app/artemizz-advanced-safety/id1642861256" target="_blank" rel="noopener noreferrer">
                  {" "}
                  <img src={app_Store} alt="app store" />
                </a>
                <p className="coming-soon">Coming soon to Android!</p>
              </Col>
            </Row>
            <Row>
              <Col
                xl={"auto"}
                className="footer-left"
                data-aos="fade-left"
                data-aos-offset="10"
              >
                <div className="footer-logo">
                  <img src={artemizz_logo} alt="Artemizz" />
                </div>
                <h6>Privacy First. Sharing Second. Safety Always.</h6>
              </Col>
              <Col>
                <Row className="footer-right">
                  <Col
                    className="mb-2 mb-lg-0"
                    data-aos="fade-left"
                    data-aos-offset="10"
                  >
                    <img src={support} alt="customer support" />
                    <h6>Customer Support</h6>
                    {/* <p>support@artemizz.com</p> */}
                    <a href="mailto:support@artemizz.com">
                      support@artemizz.com
                    </a>
                  </Col>
                  <Col
                    xs={6}
                    md={"auto"}
                    className="ps-0"
                    data-aos="fade-left"
                    data-aos-offset="10"
                  >
                    <img src={partenership} alt="partenership" />
                    <h6>Partnership Inquiries</h6>
                    {/* <p>partnerwithus@artemizz.com</p> */}
                    <a href="mailto:partnerwithus@artemizz.com">
                      partnerwithus@artemizz.com
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="footer-menu">
            <Container>
              <div className="footer-bottom-menu-list">
                <ul className="menu-list me-2">
                  {/* <li>
                    <Link to="/security">SECURITY</Link>
                  </li> */}
                  <li>
                    <Link to="/terms">TERMS</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">PRIVACY</Link>
                  </li>
                </ul>
                <ul className="social-menu-list">
                  <li>Follow us on</li>
                  <li>
                    <a
                      href="https://www.facebook.com/people/Artemizz/100085181156836/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fb} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/artemizzapp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={insta} alt="instagram" />
                    </a>
                  </li>
                  {/* <li>
                    <Link to="">
                      <img src={twitter} alt="twitter" />
                    </Link>
                  </li> */}
                </ul>
              </div>
            </Container>
          </div>
        </footer>
      </div >
    </>
  );
};

export default Homepage;
