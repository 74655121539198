const initalState = {
  forgotPasswordData: "",
};

export const forgotPassword = (state = initalState, action) => {
  switch (action.type) {
    case "FORGOT_PWD": {
      return {
        ...state,
        forgotPasswordData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
