import { React, useState } from "react";
import { Col, Navbar, Container } from "react-bootstrap";
import { logoImg, profileImg } from "../Images";
import MyProfile from "../../pages/myProfile/profile";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { decryptRequest } from "../../helper/crypto";
const Header = (loginResponse) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { loginData } = useSelector((state) => state.adminLoginData);
  const handleClick = (event) => {
    setIsActive((current) => !current);
    setIsOpen(true);
  };
  const [showMenu, setShowMenu] = useState(false);

  function sidebarToggle() {
    document.body.classList.toggle("toggle-sidebar");
  }
  const toggleHamburger = () => setShowMenu(!showMenu);
  return (
    <>
      {/* before Loign */}
      {!loginData?.data?.name ? (
        <header className="header-wrapper auth-page-header">
          <Navbar collapseOnSelect expand="md" bg="transparent">
            <Container fluid className="xs-ps-0">
              <div className="top-menu">
                <Link to="/" className="navbar-brand">
                  <img src={logoImg} alt="Artemizz" />
                </Link>
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                {/* responsive toggle button */}
                <div
                  className={`${!showMenu ? "burger-menu" : "burger-menu menu"
                    }`}
                  role="menu"
                  onClick={toggleHamburger}
                >
                  <div className="burger"></div>
                </div>
              </div>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className={`${showMenu ? "show" : ""}`}
              >
                <ul className="ms-auto right-menu navbar-nav">
                  {/* <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li> */}
                  <li>
                    <HashLink to="/#problem-need" className="nav-link">
                      About
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/#timeline" className="nav-link">
                      Features
                    </HashLink>
                  </li>
                  {/* <li>
                    <HashLink to="/#pricing" className="nav-link">
                      Pricing
                    </HashLink>
                  </li> */}
                  <li>
                    <HashLink to="/#why-artemizz" className="nav-link">
                      Why
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/#faq" className="nav-link">
                      Help
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/login" className="nav-link btn btn-primary round">
                      Sign In
                    </HashLink>
                  </li>
                </ul>
                <div className="search-menu">
                  <div className=" row">
                    <Col>
                      <div className="search-bar">
                        <input
                          type="search"
                          className="form-control"
                          name="search"
                          placeholder="Search"
                        />
                        <i className="icon-search"></i>
                      </div>
                    </Col>
                    <Col className="flex-grow-0">
                      <div
                        className="profile-detail cursor-pointer"
                        onClick={handleClick}
                      >
                        <div className="img-box">
                          <img src={profileImg} alt="Artemizz" />
                        </div>
                        <p>
                          {loginResponse?.name}{" "}
                          {!loginResponse?.lName &&
                            loginResponse?.lName}
                        </p>
                      </div>
                    </Col>
                  </div>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      ) : (
        <>
          {/* After Loign */}
          <header className="inner-header-wrapper">
            <div className="container-fluid">
              <Link to="#" className="navbar-brand cursor-default">
                <img src={logoImg} className="artemizz-logo" alt="Artemizz" />
              </Link>
              <div className="search-menu">
                <div className="row">
                  {/* <Col>
                    <div className="search-bar">
                      <input
                        type="search"
                        className="form-control"
                        name="search"
                        placeholder="Search"
                      />
                      <i className="icon-search"></i>
                    </div>
                  </Col> */}
                  <Col className="flex-grow-0 ms-0 ms-xl-auto">
                    <div
                      className="profile-detail cursor-pointer"
                      onClick={handleClick}
                    >
                      <div className="img-box">
                        {loginData?.data?.profilePic ? (
                          <img
                            src={loginData?.data?.url}
                            alt={decryptRequest(loginData?.data?.name)}
                          />
                        ) : (
                          <div className="avatar-name">
                            {decryptRequest(loginData?.data?.name).slice(0, 1)}
                          </div>
                        )}
                      </div>
                      <p>
                        {decryptRequest(loginData?.data?.name)}{" "}
                        {loginData?.data?.lName && decryptRequest(loginData?.data?.lName)}
                      </p>
                    </div>
                  </Col>
                </div>
              </div>
              <div className="toggle-btn-wrapper">
                <ul className="toggle-btn" onClick={sidebarToggle}>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </header>
          <div className={isActive ? "d-block shadow" : "shadow"}></div>
          <div className={isActive ? "show profile-bar" : "profile-bar"}>
            <div className="bar-header">
              <div className="profile-title">
                <h3 className="title-small mb-0">My Profile</h3>
                <button
                  type="button"
                  className="close-bar btn-transparent"
                  onClick={handleClick}
                >
                  <i className="icon-close"></i>
                </button>
              </div>
            </div>
            <div className="bar-body">
              {/* <MyProfile loginResponse={loginResponse} /> */}
              {/* {isActive === true ? <MyProfile handleClick={handleClick} /> : null} */}
              {isOpen === true && <MyProfile handleClick={handleClick} />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
