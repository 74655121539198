import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import axiosInstance from "../../../Services/middleware";
import { getDownloadTimeLine, getUserProfileDetail } from "../../../Services/api/endpoints";
import moment from "moment";
import 'moment-timezone';
import { dateTimeFormat } from "../../../helper/dateTimeFormat";
import { showToast } from "../../../helper/swal";

const AllFileDownloadModal = ({ openAllfileDownloadModal, closeAllfileDownloadModal, }) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).utc().local().format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).utc().local().format("MM-DD-YYYY")
  );
  const [responseTime, setResponseTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState('');

  const [profileData, setProfileData] = useState({
    isEmailVerified: "",
  });

  async function userProfileData() {
    try {
      const response = await axiosInstance.get(`${getUserProfileDetail}`);
      const apiData = response.data.data;
      setProfileData({
        ...profileData,
        isEmailVerified: apiData?.isEmailVerified,
      });
    } catch (error) {
      if (error.response) {
        console.log(error.response.data?.message);
      }
    }
  }

  let startDateValue = moment(startDate).format("MM-DD-YYYYThh:mm:ss");
  let endDateValue =  moment(endDate).format("MM-DD-YYYYThh:mm:ss");
  let data = { startDate: `${startDateValue}`, endDate: `${endDateValue}`, region: region };
  const allfileTimeline = async () => {
    if (profileData?.isEmailVerified) {
      try {
        setLoading(true);
        setResponseTime(true);
        const response = await axiosInstance.post(getDownloadTimeLine, data);
        let apiResponse = response.data;
        if (apiResponse?.httpStatus === 200 && response.data?.success === true) {
          setLoading(false);
          setResponseTime(false);
          closeAllfileDownloadModal();
          showToast(apiResponse?.message, "success", false, 4500);
        } else if (response.data?.success === false) {
          setLoading(false);
          setResponseTime(false);
          closeAllfileDownloadModal();
          showToast(apiResponse?.message, "error", true);
        }
      } catch (err) {
        if (err.response) {
          setLoading(false);
          setResponseTime(false);
          showToast(err.response.data?.message, "error", true);
        }
      }
    } else {
      showToast("You must verify your email before you can download your timeline.", "error", true);
      closeAllfileDownloadModal();
    }
  };


  // Check Email Verification and Current Timezone
  useEffect(() => {
    if (openAllfileDownloadModal) {
      const localTimezone = moment.tz.guess();
      const formattedTimezone = moment().tz(localTimezone).format('z');
      setRegion(formattedTimezone);
      userProfileData();
    }
  }, [openAllfileDownloadModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (responseTime === true) {
        closeAllfileDownloadModal();
        showToast("Downloading is in progress...", "success", false, 1500);
      }
    }, 3500);
    return () => clearTimeout(timer);
  }, [responseTime]);
  return (
    <Modal
      show={openAllfileDownloadModal}
      size="md"
      className="custom-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title>Download</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closeAllfileDownloadModal}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline-download">
          <p>Download all files for a specific date range</p>
          <div className="form-group date-selection">
            <Row>
              <Col xs={6}>
                {" "}
                <div className="date-space">
                  <DatePicker
                    className="btn datepicker"
                    selected={new Date(startDate)}
                    placeholderText="Date (From)"
                    onChange={(e) => {
                      setStartDate(new Date(e));
                    }}
                    disabledKeyboardNavigation
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className="date-space">
                  <DatePicker
                    className="btn datepicker"
                    selected={new Date(endDate)}
                    placeholderText="Date (From)"
                    minDate={moment.utc(startDate).local().toDate()}
                    onChange={(e) => {
                      setEndDate(new Date(e));
                    }}
                    disabledKeyboardNavigation
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary btn-xxl btn-round d-block m-auto btn-shadow btn-small"
              onClick={allfileTimeline}
            >
              {loading === true ? (
                <>
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                <>
                  Download <i className="icon-download ms-1"></i>
                </>
              )}
            </button>
            <button
              className="btn btn-transparent"
              onClick={closeAllfileDownloadModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AllFileDownloadModal;
