const initialState = {
  loginData: "",
};

export const adminLoginData = (state = initialState, action) => {
  switch (action.type) {
    case "LOGINDATA": {
      return {
        ...state,
        loginData: action.payload,
      };
    }

    case "UPDATEUSER": {
      return {
        ...state,
        loginData: {
          ...state.loginData,
          data: {
            ...state.loginData.data,
            name: action.payload.fName,
            lName: action.payload.lName,
            url: action.payload.url
          }
        }
      }
    }

    default: {
      return state;
    }
  }
};
